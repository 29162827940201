import React, { useState } from "react";
import { required, SelectInput, useGetList } from "react-admin";
import { Loading, Error } from "react-admin";

export const SelectInputCategory = () => {
  const { data, onLoading, error } = useGetList("categories");
  let [category, setCategory] = useState("");
  if (onLoading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  let selected = data.filter((e) => e._id == category);
  let subcategories = [];
  if (selected.length > 0) subcategories = selected[0].subCategory;
  return (
    <div>
      <SelectInput
        validate={[required()]}
        onChange={(e) => {
          setCategory(e.target.value);
        }}
        source="category"
        label="Categoría"
        choices={data
          .filter((e) => e.state)
          .map((e) => {
            return { id: e._id, name: e.name };
          })}
        format={(v) => v?._id || ""}
        parse={(v) => data.find((cat) => cat._id == v)}
      />
      <br></br>
      <SelectInput
        validate={[required()]}
        label="SubCategoría"
        source="subCategory"
        choices={subcategories.map((e) => {
          return { id: e._id, name: e.name };
        })}
        format={(v) => v?._id || ""}
        parse={(v) => subcategories.find((subcat) => subcat._id == v)}
      />
    </div>
  );
};
