import { Card, CardContent, Typography, Grid, Box, Chip, IconButton, Divider, Menu, MenuItem, Dialog, DialogContent, CircularProgress, DialogTitle, Rating, TextField as MuiTextField, FormControl, InputLabel, Select } from "@mui/material";
import { Button, CreateButton, DateInput, ExportButton, FilterButton, List, SelectInput, TextInput, TopToolbar, useDataProvider, useGetList, useGetOne, useListContext, useNotify, usePermissions, useRedirect, useRefresh } from "react-admin";
import { useParams } from "react-router-dom";
import { Datagrid, TextField, ArrayField, SingleFieldList, ChipField, DateField } from "react-admin";
import { AddComment, Email, Event, Female, HelpOutline, Male, MoreVert, Phone, Star } from "@mui/icons-material";
import { format, set } from "date-fns";
import { es } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import moment from "moment";
import useExport from "../../../components/UseExcelExport";
import RegexSearchInput from "../../../components/RegexSearchInput";
import CloseIcon from "@mui/icons-material/Close";

const WhatsAppField = ({ phone, countryCode }) => {
    if (!phone) return null;
    return (
        <a href={`https://wa.me/${countryCode}${phone}`} target="_blank" rel="noreferrer" style={{ textDecoration: "underline", color: "#FF770D" }}>
            {phone}
        </a>
    );
};

const InfoItem = ({ icon, label, value, countryCode }) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
            <Box display="flex" alignItems="center">
                {icon}
                <Typography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                    {label}:
                </Typography>
            </Box>
            {label === "Teléfono" ?
                <WhatsAppField phone={value} countryCode={countryCode} />
                :
                <Typography variant="body2">{value}</Typography>
            }
        </Box>
    );
}

// Función para obtener el ícono de género
const getGenderIcon = (gender) => {
    if (gender?.toLowerCase() === "hombre") return <Male fontSize="small" />;
    if (gender?.toLowerCase() === "mujer") return <Female fontSize="small" />;
    return <HelpOutline fontSize="small" />;
};

// Función para capitalizar la primera letra
const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
const ListActions = (props) => {
    const { getExportData, exportData, setExportData } = useExport("booking");
    const [openModal, setOpenModal] = useState(false);
    const { filterValues } = useListContext();

    const handleExportClick = async () => {
        if (!exportData.length) {
            setOpenModal(true);
            await getExportData(filterValues);
            setOpenModal(false);
        }
    };

    const handleClearData = () => {
        setExportData([]);
    };

    const columns = [
        { label: "ID", source: "_id" },
        { label: "Fecha de la creación de la reserva", source: "createdAt", transform: (val) => moment(val).format('YYYY-MM-DD') },
        { label: "Hora de la creación de la reserva", source: "createdAt", transform: (val) => moment(val).format('HH:mm') },
        { label: "Fecha de la reserva", source: "bookingDate", transform: (val) => moment(val).format('YYYY-MM-DD') },
        { label: "Hora de la reserva", source: "bookingDate", transform: (val) => moment(val).format('HH:mm') },
        { label: "Nombre del cliente", source: "customer.fullName" },
        { label: "Teléfono del cliente", source: "customer.phone" },
        { label: "Fecha de nacimiento", source: "customer.birthdate", transform: (val) => moment(val).format('YYYY-MM-DD') },
        { label: "Género", source: "customer.gender" },
        { label: "Tipo de descuento", source: "paymentInfo.coupon.discountType" },
        { label: "Descuento", source: "paymentInfo.coupon.discount" },

        {
            label: "Dirección del cliente",
            source: "customer.address",
            transform: (address) => {
                if (!address || !address.street || !address.house || address.reference === undefined) {
                    return "";
                }
                return `${address.street} #${address.house}, ${address.reference}`;
            }
        },
        { label: "Método de Reserva", source: "createdFrom" },
        { label: "Servicio en Sucursal", source: "isInBranch", transform: (isInBranch) => isInBranch ? "Si" : "No" },
        { label: "Servicios Solicitados", source: "serviceCart", transform: (services) => services.map((service) => service.service.name).join(", ") },
        { label: "Cantidad de Servicios Solicitados", source: "serviceCart", transform: (services) => services.length },
        { label: "Precio Total", source: "paymentInfo.totalPrice" },
        { label: "Cupón de descuento", source: "coupon.name" },
        { label: "Notas", source: "notes" },
        { label: "Empleado", source: "employee.fullName" },
        { label: "Método de Pago", source: "paymentInfo.paymentMethod" },
        { label: "Estado de Reserva", source: "status" },
        { label: "Estado de Pago", source: "paymentInfo.status" },
        { label: "Sucursal", source: "branch.name" },
        { label: "Calificación", source: "feedbackInfo.rating" },
        { label: "Comentario", source: "feedbackInfo.comment" },
    ];

    return (
        <TopToolbar
            style={{
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                padding: "0 16px",
                marginBottom: "0",
            }}
        >
            <div style={{ display: "flex", gap: "16px" }}>
                <FilterButton filters={props.filters} />
                <CreateButton basePath={props.basePath} />
            </div>
            <ExportButton onClick={handleExportClick} data={exportData} columns={columns} filename="bookings.xlsx" clearData={handleClearData} />
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <CircularProgress />
                        <Typography variant="body1" style={{ marginTop: 16 }}>
                            Cargando datos para exportación...
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </TopToolbar>
    );
};
// const SelectBranchInput = ({ serviceMethod, branch, setBranch }) => {
//     const { data, loading, error } = useGetList("enterprise/branches", {
//         filter: {
//         status: true,
//         },
//     });

//     return serviceMethod !== "A domicilio" ? (
//         <FormControl>
//         <InputLabel id="demo-simple-select-label">Sucursal</InputLabel>
//         <Select
//             style={{ width: 200 }}
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={branch}
//             label="Método"
//             onChange={(e) => setBranch(e.target.value)}
//         >
//             {data?.map((e) => {
//             return (
//                 <MenuItem key={e._id} value={e._id}>
//                 {e.name}
//                 </MenuItem>
//             );
//             })}
//         </Select>
//         </FormControl>
//     ) : (
//         <React.Fragment />
//     );
// };

// const BookingFilter = [

//     <DateInput label="Desde" source="bookingDate>" />,
//     <DateInput label="Hasta" source="bookingDate<" />,
//     <RegexSearchInput source="employee.fullName" label="Buscar Empleado" alwaysOn />,
//     <RegexSearchInput source="branch.name" label="Buscar Sucursal" alwaysOn />,
// ];
const CustomerServicesPage = () => {
    const { id } = useParams();
    const { data: customer } = useGetOne("enterprise/customers", { id });
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const [openRatingDialog, setOpenRatingDialog] = useState(false);
    const [rating, setRating] = useState(0);
      const [ingresosTotal, setIngresosTotal] = useState(0);
    const [detail, setDetail] = useState(customer?.detail || "");
    useEffect(() => {
        setDetail(customer?.detail || "");
        setRating(customer?.rating || 0);
    }, [customer])
    const handleOpenRatingDialog = () => setOpenRatingDialog(true);
    const handleCloseRatingDialog = () => setOpenRatingDialog(false);
    const handleSubmitRating = async () => {
        if (!detail) return;
        const updatedCustomer = { ...customer, rating, detail };
        if (updatedCustomer.countryCode === undefined) {
            updatedCustomer.countryCode = "591"
        }
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${process.env.REACT_APP_API_URL}/enterprise/customers/${customer._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedCustomer)
            });
            notify("Calificación guardada con éxito", { type: "success" });
            refresh();
            if (!response.ok) {
                throw new Error("Error al actualizar el cliente");
            }
        } catch (error) {
            console.error("Error al actualizar detalle del cliente:", error);
            notify(`Error: ${error.message}`, { type: "error" });
        }
        setOpenRatingDialog(false);
    };
    // const { data: bookings, isLoading, } = useGetList("booking", {
    //     filter: { "customer._id": customer._id, status: "Finalizado" },
    //     sort: { field: "bookingDate", order: "DESC" },
    //     pagination: { page: 1, perPage: 100 }, // Ajustar si hay más de 100 reservas
    // });

    const handleDetailBlur = async() => {
        if (!detail) return;
        if (!customer) return;
        const updatedCustomer = { ...customer, detail };
        if(updatedCustomer.countryCode === undefined){
            updatedCustomer.countryCode = "591"
        } 
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${process.env.REACT_APP_API_URL}/enterprise/customers/${customer._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedCustomer)
            });
            
            if (!response.ok) {
                throw new Error("Error al actualizar el cliente");
            }
            console.log(`Cliente ${customer._id} actualizado con detalle:`, detail);
        } catch (error) {
            console.error("Error al actualizar detalle del cliente:", error);
        }
    };
    const TotalAmount = ({ resource, setTotal, showInline, totalType, filters, setEgresosEmpleado }) => {  
      const dataProvider = useDataProvider();
      const { filterValues } = useListContext();
      const totalfilter = {...filters, ...filterValues};
      console.log(totalfilter)
      const [localTotal, setLocalTotal] = useState(0);
      const [precioTotal, setPrecioTotal] = useState(0);
      const [isLoading, setIsLoading] = useState(true); 
      useEffect(() => {
        const fetchTotalAmount = async () => {
          try {
            const response = await dataProvider.getList(resource, {
              pagination: { page: 1, perPage: 10 },
              filter: totalfilter,
            });
            console.log(response)
            let total = 0;
    
            if (totalType === "egresos") {
              total = response.totalFeeAmount || 0;
              setEgresosEmpleado(response.totalFeeAmountEmployee)
            } else {
              total = response.totalAmount || 0; 
            }
            setPrecioTotal(response.totalFeeAmountEmployee);
            setTotal(total);
            setLocalTotal(total);
          } catch (error) {
            setTotal(0);
            setLocalTotal(0);
          } finally {
            setIsLoading(false); // Desactivamos el loading al finalizar la petición
          }
        };
    
        fetchTotalAmount();
      }, [dataProvider, resource, setTotal, totalType, filterValues]);
      if (!showInline) return null;
        return (
            <div>
            {isLoading ? (
                <Typography variant="h6" sx={{ margin: 1, padding: 0, paddingLeft: 1, color: "gray" }}>
                Calculando...
                </Typography>
            ) : totalType === "egresos" ? (
                <div>
                <Typography variant="h6" sx={{ margin: 1, padding: 0, paddingLeft: 1 }}>
                    Total comisiones por empleado: Bs. {precioTotal}
                </Typography>
                <Typography variant="h6" sx={{ margin: 1, padding: 0, paddingLeft: 1 }}>
                    Total comisiones por plataforma: Bs. {localTotal}
                </Typography>
                </div>
            ) : (
                <Typography variant="h6" sx={{ margin: 1, padding: 0, paddingLeft: 1 }}>
                Total Ingresos: Bs. {localTotal}
                </Typography>
            )}
            </div>
        );
    };
    const { data: branches, isLoading } = useGetList("enterprise/branches", {
        filter: { status: true },
    });
    const { permissions } = usePermissions();
    const BookingFilter = [
        <DateInput label="Desde" source="bookingDate>" />,
        <DateInput label="Hasta" source="bookingDate<" />,
        <RegexSearchInput source="employeeName" label="Buscar Empleado" alwaysOn />,
        permissions !== "admin-sucursal" ?  (
            <SelectInput 
                label="Sucursal" 
                source="branch" 
                alwaysOn
                choices={[
                    { id: "", name: "Todas las sucursales" }, 
                    ...(branches?.map(branch => ({ id: branch._id, name: branch.name })) || [])
                ]}
            />
        ): null,
    ].filter(Boolean);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ mt: 8 }}> 
                <Card>
                    <CardContent>
                        {/* Encabezado con nombre y menú */}
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                <Typography variant="h6" fontWeight="bold">
                                    {customer?.fullName || "Cliente Desconocido"}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Cliente registrado
                                </Typography>
                            </Box>
                            <IconButton onClick={handleMenuOpen}>
                                <MoreVert />
                            </IconButton>
                        </Box>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={() => redirect(`/enterprise/customers/${id}`)}>
                                Editar
                            </MenuItem>
                            <MenuItem onClick={handleOpenRatingDialog}>Calificar</MenuItem>
                        </Menu>

                        <Divider sx={{ my: 2, bgcolor: "rgba(0, 0, 0, 0.1)" }} />
                        <Box mt={2}>
                            <InfoItem icon={<Phone fontSize="small" />} label="Teléfono" value={customer?.phone || "No disponible"} countryCode={customer?.countryCode} />
                            <InfoItem icon={<Email fontSize="small" />} label="Correo" value={customer?.email || "No disponible"} />
                            <InfoItem icon={getGenderIcon(customer?.gender)} label="Género" value={capitalizeFirstLetter(customer?.gender) || "No especificado"} />
                            {customer?.lastBooking?.bookingDate && (
                                <InfoItem
                                    icon={<Event fontSize="small" />}
                                    label="Última Reserva"
                                    value={
                                        <Chip
                                            label={format(new Date(customer.lastBooking.bookingDate), "PPpp", { locale: es })}
                                            sx={{ backgroundColor: "white", "-.MuiChip-label": { padding: "0" } }}
                                        />
                                    }
                                />
                            )}
                            <InfoItem
                                icon={<Star fontSize="small" />}
                                label="Calificación"
                                value={
                                    customer?.rating === undefined ? "No tiene" :
                                        <Rating
                                            name="customer-rating"
                                            value={customer?.rating || 0}
                                            readOnly
                                            precision={0.5} // Permite medias estrellas si es necesario
                                            sx={{ fontSize: "1.5rem" }} // Ajusta el tamaño de las estrellas
                                        />
                                }
                            />
                            <Box mt={2}>
                                <Box display="flex" alignItems="center">
                                    <AddComment fontSize="small"></AddComment>
                                    <Typography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                                        Detalles del cliente:
                                    </Typography>
                                </Box>
                                <MuiTextField
                                    label="Detalle"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={detail}
                                    onChange={(e) => setDetail(e.target.value)}
                                    sx={{ mt: 2 }}
                                    placeholder="Agrega un detalle..."
                                    onBlur={() => handleDetailBlur()}
                                />
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>

            {/* Sección de Servicios (75%) */}
            <Grid item xs={12} md={9}>
                {/* <Typography variant="h5">Servicios Solicitados</Typography> */}
                {customer && customer._id && (
                    <List resource="booking" filter={{ "customer": customer._id, "status": "Finalizado" }} 
                        filters={BookingFilter}
                        actions={<ListActions filters={BookingFilter} />}
                        title="Historial de servicios realizados"
                        sort={{ field: "bookingDate", order: "DESC" }}
                    >
                        <Datagrid rowClick="show" bulkActionButtons={false}>
                            <TextField source="_id" label="ID" />
                            <TextField source="employee.fullName" label="Empleado" />
                            <DateField source="bookingDate" label="Fecha de Reserva" showTime locales="es-ES" />
                            <ArrayField source="serviceCart" label="Servicios Solicitados">
                                <SingleFieldList>
                                    <ChipField source="service.name" />
                                </SingleFieldList>
                            </ArrayField>
                            <TextField source="branch.name" label="Sucursal" />
                            <TextField source="paymentInfo.status" label="Estado de Pago" />
                            <TextField source="paymentInfo.totalPrice" label="Precio(Bs.)" />
                        </Datagrid>
                        <TotalAmount resource="booking" setTotal={setIngresosTotal} showInline totalType="ingresos" filters={{ "customer": id, "status": "Finalizado" }} />
                    </List>
                )}
            </Grid>
            <Dialog open={openRatingDialog} onClose={handleCloseRatingDialog} maxWidth="xs" fullWidth>
                <DialogTitle sx={{ textAlign: "center" }}>
                    Calificando a {customer?.fullName}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseRatingDialog}
                        sx={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ padding: "24px" }}>
                    <Typography align="center" variant="body1" sx={{ marginBottom: 2 }}>
                        Por favor, califica al cliente según su comportamiento y deja un detalle adicional si lo deseas.
                    </Typography>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Rating
                            name="customer-rating"
                            value={rating}
                            onChange={(event, newValue) => setRating(newValue)}
                            precision={0.5}
                            sx={{ fontSize: "3.5rem" }}
                        />
                    </Box>
                    <MuiTextField
                        label="Detalle"
                        fullWidth
                        multiline
                        value={detail}
                        onChange={(e) => setDetail(e.target.value)}
                        sx={{ mt: 2 }}
                        placeholder="Agrega un detalle..."
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmitRating}
                        sx={{ mt: 2, padding: 1.3, color: "white" }}
                    >
                        Guardar Calificación
                    </Button>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default CustomerServicesPage;
