import React, { createContext, useContext, useState } from "react";

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    "bookingDate>": undefined,
    "bookingDate<": undefined,
    "branch._id": undefined,
    "employee._id": undefined,
  });

  const updateFilter = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value || null,
    }));
  };

  // clear filters
  const clearFilters = () => {
    setFilters(Object.keys(filters).reduce((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {}));
  };

  return <FilterContext.Provider value={{ filters, updateFilter, clearFilters }}>{children}</FilterContext.Provider>;
};

export const useFilterContext = () => useContext(FilterContext);
