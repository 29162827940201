import React, { useState } from "react";
import { useNotify, Notification, useCreate } from "react-admin";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Background from "./assets/bg-desktop.png";
import avatarLogo from "./assets/avatar.png";
import { StyledEngineProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useLocation } from 'react-router-dom';
const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    minWidth: 300,
    width: 300,
    backgroundColor: "transparent",
  },
  avatar: {
    margin: "1em",
    textAlign: "center ",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    display: "flex",
  },
  hint: {
    textAlign: "center",
    marginTop: "1em",
    color: "#ccc",
  },
};
const ForgotPasswordPage = (props) => {
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: "#FF770D",
      },
      secondary: {
        main: "#000",
      },
    },
  });
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const notify = useNotify();
  const [register, { isLoading }] = useCreate();
  let location = useLocation();
  const submit = (e) => {
    e.preventDefault();
    if (email) { 
      register(
        "forgotPassword" + location.search,
        {
          data: { email: email.toLowerCase() },
        },
        {
          onSuccess: ({ data }) => {
            setSuccess(true);
            notify(
              "Solicitud procesada con éxito. Revisa la bandeja de entrada de tu email."
            );
          },
          onError: (error) => notify(`${error.message}`, "error"),
          onFailure: (error) => notify(`${error}`, "warning"),
        }
      );
    } else {
      notify("Debes ingresar tu email", "warning");
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <div
          style={{
            ...styles.main,
            backgroundColor: "#252526",
            backgroundImage: `url(${Background})`,
            backgroundSize: "auto",
          }}
        >
          <Card style={styles.card}>
            <div style={styles.avatar}>
              <Avatar
                backgroundColor="transparent"
                src={avatarLogo}
                variant="square"
                style={{ height: 350, width: 225, borderRadius: 0 }}
              />
            </div>
            <form onSubmit={submit}>
              <div style={styles.form}>
                <div style={{ ...styles.input, marginBottom: 15 }}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <Box sx={{ "& button": { m: 1 }, "& a": { m: 1 } }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#FF770D",}}
                  type="submit"
                  fullWidth
                  disabled={success || isLoading}
                >
                  Recuperar contraseña
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  href={process.env.PUBLIC_URL + "/#/login"}
                >
                  Atrás
                </Button>
              </Box>
            </form>
          </Card>
          <Notification />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ForgotPasswordPage;
