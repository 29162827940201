import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import avatarLogo from "./assets/avatar.png";
import wappLogo from "./assets/wapp.png";
import teAyudoLogo from "./assets/te-ayudo-prov.png";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import Firebase from "./Firebase/Firebase";
import YouTubePlaylist from "@codesweetly/react-youtube-playlist";

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    // justifyContent: 'center',
  },
  card: {
    minWidth: 300,
    backgroundColor: "transparent",
  },
  avatar: {
    margin: "1em",
    textAlign: "center ",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    display: "flex",
  },
  hint: {
    textAlign: "center",
    marginTop: "1em",
    color: "#ccc",
  },
};

const Dashboard = () => {
  const muiTheme = createTheme();
  return (
    <Firebase>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <div
            style={{
              ...styles.main,
              backgroundSize: "contain",
            }}
          >
            <h1>Bienvenido al Dashboard de administración</h1>
            <Card style={{ ...styles.card, backgroundColor: "#252526" }}>
              {/* <CardHeader style={{ color: "white" }} title="Bienvenido al Dashboard de administración" /> */}
              {/* <Card style={styles.card}> */}
              <div style={styles.avatar}>
                <Avatar backgroundColor="transparent" src={avatarLogo} variant="square" style={{ height: 350, width: 225, borderRadius: 0 }} />
              </div>
              <div style={styles.avatar}>
                <Avatar backgroundColor="transparent" src={teAyudoLogo} variant="square" style={{ height: 101, width: 254, borderRadius: 0 }} />
              </div>
            </Card>
            <CardHeader title="Contacto, ayuda y soporte técnico al: 78104295" />
            <a
              href="https://wa.me/59178104295"
              target="_blank"
              style={{
                flexDirection: "row",
                display: "flex",
                color: "black",
              }}
            >
              <CardHeader title="Contáctanos por WhatApp: "></CardHeader>
              <Avatar backgroundColor="transparent" src={wappLogo} variant="square" style={{ height: 50, width: 50, borderRadius: 0 }} />
            </a>
            <CardHeader title="Email: contacto@teayudo.com.bo" />
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </Firebase>
  );
};
export default Dashboard;
