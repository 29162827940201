import React from "react";
import {
  Create as BaseCreate,
  SimpleForm,
  SelectInput,
  required,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  useChoicesContext,
  NumberInput,
  SimpleShowLayout,
} from "react-admin";

import { useFormContext, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { SelectCustomerInput } from "../Booking/SelectCustomer";

export const Create = (props) => {
  const transform = (data) => {
    const provider = JSON.parse(localStorage.getItem("profile"));
    const sale = {
      ...data,
      saleDetails: data.saleDetails.map((e) => {
        return {
          product: e,
          quantity: e.quantity,
          price: e.quantity * e.price,
        };
      }),
      totalPrice: data.saleDetails?.reduce(
        (a, b) =>
          a.price * a.quantity || a + (b.price || 0) * (b.quantity || 0),
        0
      ),
      paymentMethod: "Efectivo",
      client: {
        ...data.customer,
      },
      direction: data.address,
      state: "Confirmado",
      provider: {
        ...provider,
        name: provider.first_name,
        logoURL: provider.picture,
      },
    };
    if (data.employee) {
      sale.employee = {
        ...data.employee,
        fullName: data.employee.first_name + " " + data.employee.last_name,
        photo: data.employee.picture,
        CI: data.employee.CI_NIT,
        pushToken: data.employee.pushToken,
      };
    } else {
      sale.employee = null;
    }
    return sale;
  };

  return (
    <BaseCreate {...props} transform={transform}>
      <SimpleForm>
        <SimpleShowLayout></SimpleShowLayout>
        <SelectCustomerInput />
        <br />
        <ArrayInput
          validate={[required()]}
          source="saleDetails"
          label="CARRITO"
        >
          <SimpleFormIterator disableReordering>
            <ReferenceInput reference="product">
              <SelectProductInput />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TotalFields />
        <ReferenceEmployee />
      </SimpleForm>
    </BaseCreate>
  );
};

const SelectEmployeeInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  return (
    <SelectInput
      {...props}
      label="Empleado"
      optionText={(value) => {
        return value.first_name + " " + value.last_name;
      }}
      optionValue="_id"
      parse={(value) => {
        return choicesContext?.allChoices.find((e) => e._id === value);
      }}
      format={(value) => {
        return value?._id;
      }}
    />
  );
};

const ReferenceEmployee = () => {
  return (
    <ReferenceInput label="Empleado" source="employee" reference="employeeSale">
      <SelectEmployeeInput />
    </ReferenceInput>
  );
};

const SelectProductInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const product = useWatch({ name: choicesContext.source });
  return (
    <React.Fragment>
      <SelectInput
        {...props}
        label="Producto"
        optionText="name"
        parse={(value) => {
          return {
            ...choicesContext?.allChoices.find((e) => e._id === value),
            quantity: product.quantity || 1,
          };
        }}
        format={(value) => {
          return value.name;
        }}
      />
      <NumberInput
        source={choicesContext.source + ".quantity"}
        step={1}
        defaultValue={1}
        label="Cantidad"
      />

      <Box sx={{ "& div": { m: 1 } }}>
        <TextField
          disabled
          defaultValue={0}
          value={product.price}
          label="Precio Unitario (Bs.)"
        />
        <TextField
          disabled
          defaultValue={0}
          value={product.price * product.quantity || 0}
          label="Subtotal (Bs.)"
        />
      </Box>
      <br />
    </React.Fragment>
  );
};

const TotalFields = () => {
  const saleDetails = useFormContext().watch("saleDetails");  
  return (
    <Box sx={{ "& div": { m: 1 } }}>
      <TextField
        disabled
        value={
          saleDetails?.reduce(
            (a, b) =>
              a.price * a.quantity || a + (b.price || 0) * (b.quantity || 0),
            0
          ) || 0
        }
        label="Precio Total (Bs.)"
      />
    </Box>
  );
};
