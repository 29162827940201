import React from "react";
import {
  TextField,
  Edit as BaseEdit,
  SimpleForm,
  TextInput,
  DateField,
  SimpleShowLayout,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { useSearchParams } from "react-router-dom";

// CustomToolbar con los botones de Guardar y Eliminar
const CustomToolbar = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleDeleteSuccess = () => {
    notify("Eliminado con éxito", { type: "success" });
    redirect("/unavailability"); // Redirecciona a la lista de indisponibilidad
    refresh();
  };

  const handleDeleteFailure = (error) => {
    notify(`Error: ${error.message}`, { type: "warning" });
  };

  return (
    <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        mutationMode="pessimistic"
        onSuccess={handleDeleteSuccess}
        onFailure={handleDeleteFailure}
        confirmTitle="Confirmación de eliminación"
        confirmContent="¿Estás seguro de que deseas eliminar este registro? Esta acción no se puede deshacer."
      />
    </Toolbar>
  );
};

export const Edit = ({ classes, ...props }) => {
  const [searchParams] = useSearchParams();
  return (
    <BaseEdit
      {...props}
      redirect={(resource, id, data, state) => {
        const redirectParam = searchParams.get("redirect")
          ? searchParams.get("redirect") + "?date=" + data.startDate
          : null;
        return redirectParam ?? "unavailability";
      }}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <SimpleShowLayout>
          <TextField source="employee.fullName" label="Empleado" />
          <DateField source="startDate" label="Fecha de Inicio" showTime />
          <DateField source="endDate" label="Fecha Final" showTime />
          <DateField source="deletedAt" label="Borrado" />
        </SimpleShowLayout>
        <br />
        <TextInput source="reason" label="Detalle" />
      </SimpleForm>
    </BaseEdit>
  );
};
