import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  TabbedForm,
  FormTab,
  Edit,
  TextInput,
  required,
  TextField,
  ImageField,
  ImageInput,
  useDataProvider,
  SelectArrayInput,
  FunctionField,
} from "react-admin";
const PREFIX = "ProfileEdit";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledEdit = styled(Edit)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}
const ProfileEdit = ({ ...props }) => {
  const dataProvider = useDataProvider();
  const [services, setServices] = useState([]);

  useEffect(() => {
    dataProvider
      .getList("service")
      .then(({ data }) => {
        setServices(data);
      })
      .catch((error) => {
        console.error("Error fetching services: ", error);
      });
  }, [dataProvider]);

  const validateSelection = (value) => {
    if (value && value.length > 3) {
      return "Solo puedes seleccionar hasta 3 servicios";
    }
    return undefined;
  };

  return (
    <StyledEdit
      // I don't need any redirection here, there's no list page
      {...props}
      id="my-profile"
      resource="profile" //"profile"
      basePath="/my-profile"
      redirect={false}
      title="Mi Perfil"
    >
      <TabbedForm>
        <FormTab label="Datos Generales">
          <ImageInput
            validate={[required()]}
            format={formatLogo}
            source="picture"
            label="Logo de Empresa"
            accept=".jpeg,.jpg,.png,.jfif"
            placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
          {/* <TextField source="email" label="Email" /> */}
          <TextInput
            source="first_name"
            validate={required()}
            label="Nombre de Empresa"
          />
          <TextInput
            source="business_name"
            validate={required()}
            label="Razón Social"
          />
          <TextInput source="phone" validate={required()} label="Teléfono" />
          <TextInput source="CI_NIT" validate={required()} label="NIT" />
          <SelectArrayInput
            source="recommendedServices"
            choices={services}
            optionValue="_id"
            optionText="name"
            style={{ width: 200 }}
            validate={[validateSelection]}
            label="Servicios Recomendados"
          />
          <FunctionField
            label="Web URL"
            textAlign="center"
            render={(user) =>
              process.env.REACT_APP_WEB_CLIENT_URL + "/" + user.slugUrl
            }
          />
        </FormTab>
      </TabbedForm>
    </StyledEdit>
  );
};

export default ProfileEdit;
