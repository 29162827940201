import React, { useState, useEffect } from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  DateInput,
  useRefresh,
  ArrayField,
  ChipField,
  SingleFieldList,
  useListContext,
} from "react-admin";
import Paper from "@mui/material/Paper";

const MyDatagrid = (props) => {
  let sum = 0;
  let paid = 0;
  const { data } = useListContext();
  data?.forEach((e, index) => {
    sum += e.totalPrice;
    if (e.paymentStatus === "Pagado") {
      paid += e.totalPrice;
    }
  });
  props.totalIn(sum, paid);
  return <Datagrid bulkActionButtons={false} {...props} />;
};

const BalanceFilter = (props) => (
  <Filter {...props}>
    <DateInput label="Desde" source="createdAt>" />
    <DateInput label="Hasta" source="createdAt<" />
  </Filter>
);

export const BalanceList = (props) => {
  let [totalIn, setTotalIn] = useState(0);
  let [paid, setPaid] = useState(0);

  const callbackFunction = (childData, paid) => {
    setTotalIn(childData);
    setPaid(paid);
  };

  const refresh = useRefresh();
  useEffect(() => {
    const interval = setInterval(() => refresh(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <List
        sort={{ field: "startDate", order: "DESC" }}
        bulkActionButtons={false}
        filters={<BalanceFilter />}
        {...props}
      >
        <MyDatagrid rowClick="show" totalIn={callbackFunction}>
          <TextField source="_id" label="ID" />
          <ArrayField source="saleDetails" label="Productos">
            <SingleFieldList>
              <ChipField source="product.name" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="employee.fullName" label="Empleado" />
          <DateField
            source="createdAt"
            label="Fecha"
            showTime
            locales="es-ES"
          />
          <TextField source="totalPrice" label="Precio Total (Bs.)" />
          <TextField source="paymentMethod" label="Método de Pago" />
          <TextField source="paymentStatus" label="Estado de Pago" />
        </MyDatagrid>
      </List>
      <div>
        <Paper>
          <h3>Ingresos Totales: Bs.{totalIn.toFixed(2)}</h3>
        </Paper>
        <Paper>
          <h3>Total Pagado: Bs.{paid.toFixed(2)}</h3>
          <h3>Total Pendiente: Bs.{(totalIn - paid).toFixed(2)}</h3>
        </Paper>
      </div>
    </div>
  );
};
