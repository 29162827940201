import React, { useState, useEffect } from 'react';

const TyC = () => {
    return (
        <div>
            
            ASPECTOS GENERALES<br></br><br></br>
            INFORMACIÓN<br></br>
<br></br>
            INVERSIONESPIVES S.R.L. es una sociedad constituida conforme a las leyes bolivianas, identificada con Matricula de Comercio N° 431104 debidamente regisrtada en FUNDEMPRESA, con domicilio en la ciudad de Santa Cruz de la Sierra, que para los efectos de los presentes términos se denominará EL OPERADOR.<br></br>
<br></br>
            NATURALEZA JURÍDICA<br></br>
<br></br>
            Los presentes términos y condiciones de uso regulan la relación contractual de carácter comercial que une a los Consumidores o Clientes finales que acceden a la plataforma virtual y al PROVEEDOR de servicio a través de la plataforma de el OPERADOR que hace su participación encalidad de intermediario de comunicación o datos, especialmente en la autorización de uso que otorga éste en favor de aquellos Consumidores y Proveedores.<br></br>
<br></br>
            DEFINICIONES<br></br>
            Mensajes de datos: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI), Internet, el correo electrónico, el telegrama, el télex o el telefax, o cualquier otra información o reqeurimiento generado a través de la aplicación o plataforma TE AYUDO.<br></br>
<br></br>
            Comercio Electrónico: Comprende el envío, transmisión, recepción, almacenamiento de mensajes de datos por vía electrónica. Como asi también firmas electrónicas de documentos.<br></br>
<br></br>
            Cookies: Cadenas de texto enviadas virtualmente que son almacenadas por el uso de La Plataforma por el Operador, para la consulta de las actividades y preferencias de los usuarios.<br></br>
<br></br>
            Consumidores o Cliente Final: Toda persona natural que, como destinatario final, use La Plataforma para solicitar por medio de ésta un mandato remunerado, cuyo encargo consiste en la celebración de un contrato de compraventa de servicio o producto, u otro tipo de contrato lícito, con el fin de adquirir bienes o servicios.<br></br>
<br></br>
            Mandatario o Proveedor: Persona natural que acepta realizar la gestión del encargo, servicio o venta de producto o bienes solicitado por el Consumidor a través de la Plataforma.<br></br>
<br></br>
            Operador de La Plataforma: Encargado de administrar operativamente y funcionalmente la Plataforma, representado para los efectos de los presentes términos por INVERSIONESPIVES S.R.L., o por la persona natural o jurídica que ésta designe.<br></br>
            Contrato de mandato remunerado celebrado por medios electrónicos: Aquel acuerdo de voluntades celebrado entre el Consumidor y el Mandatario a través de la Plataforma TE AYUDO, por medio del cual el Consumidor solicita, a través de la Plataforma, la gestión de un encargo, servicio, bien o producto al mandatario, obligándose este último a cumplir con dicho encargo, servicio entrega de bien o producto por cuenta y riesgo propia y única del Mandatario, a cambio de recibir una remuneración como contraprestación.<br></br>
<br></br>
            Datos personales: Es toda información que permite identificar o hacer identificable a una persona física, la cual desde el momento de suscribir un contrato electrónico, autoriza a la Plataforma al uso de dicha información para usos licitos y relacionados con el negocio comercial.<br></br>
<br></br>
            Interacción en La Plataforma: Facultad de acceso por parte de los Consumidores para conocer los productos y servicios exhibidos por el OPERADOR y que publicitan LOS PROVEEDORES, la publicidad puesta a disposición en la Plataforma y manifestar su voluntad de solicitar un encargo.<br></br>
<br></br>
            Mayor de edad: Persona natural mayor de dieciocho (18) años.<br></br>
<br></br>
            Te Ayudo podrá comunicarse con el usuario a través de otros canales como Whatsapp y cualquier otro medio de acceso al usuario siempre y cuando éste haya compartido la información con Te Ayudo mediante su previa autorización a la Política de tratamiento de datos personales. Te Ayudo únicamente será responsable por las comunicaciones y/o notificaciones enviadas desde este número (78104295) y/o canales adicionales que establezca para dicho fin. Te Ayudo no será responsable frente al usuario o terceros, por mensajes y comunicaciones enviadas desde canales que Te Ayudo no reconozca como propios.<br></br>
<br></br>
            Pasarela de pagos: Servicio que permite la realización de pagos por parte de los Consumidores directamente a los Mandatarios, a través de medios electrónicos utilizando plataformas tecnológicas (software).<br></br>
<br></br>
            La Plataforma: Aplicativo web y móvil administrado por el OPERADOR, que permite la concurrencia de Consumidores y Mandatarios para que por medio de contratos de mandato el Consumidor solicite la gestión de un encargo o servicio o compra venta de bienes y/o productos.<br></br>
<br></br>
            Publicidad: Es toda forma de comunicación realizada por el OPERADOR, con el fin de brindar información sobre productos, bienes, servicios, actividades comerciales y comunicar estrategias o campañas publicitarias o de mercadeo, propias o de terceros; realizada como mecanismo de referencia y no como oferta pública.<br></br>
<br></br>
            Producto: Bien de consumo exhibido a través de la Plataforma.<br></br>
<br></br>
            Servicio: Servicios exhibidos a través de la Plataforma y cualquier otro que esté disponible a través de la misma.<br></br>
            Términos y condiciones de uso de La Plataforma: Constituyen los términos que han de regular el uso que los Consumidores dan a La Plataforma, así como las relaciones contractuales que se pueden generar entre Consumidores y Mandatarios.<br></br>
<br></br>
            Ventanas emergentes (Pop-Ups): Ventana o aviso de internet que emerge automáticamente en cualquier momento cuando se utiliza la Plataforma, especialmente utilizado para la formalización del contrato de mandato entre Consumidores y Mandatarios, o para efectos de publicitar algun bien o servicio.<br></br>
<br></br>
            OBJETO<br></br>
<br></br>
            Los presentes términos y condiciones regulan la autorización de uso que otorga el Operador a los Consumidores, para que éstos ingresen a la plataforma virtual, se informen sobre los productos o servicios de consumo exhibidos, para que sean utilizados como referencia y puedan solicitar la gestión de un producto o servicio, por medio de un contrato de mandato con el Mandatario, en la que el Operador tiene una pacticipacion como transmisor o intermediario de datos entre OCnsumidor final y Proveedor.<br></br>
<br></br>
            El Operador a través de la Plataforma realiza las siguientes acciones: i) exhibe diferentes productos y servicios de consumo de forma publicitaria para que puedan servir de referencia a los Consumidores, ii) facilita el encuentro entre Consumidores y Mandatario para la realización del vínculo contractual, iii) permite el uso de la plataforma de pagos iv) sirve de medio de envío de comunicaciones entre los Consumidores y los Mandatarios.<br></br>
<br></br>
            La celebración de la relación contractual entre Consumidores y Mandatarios, se da con Consumidores que se encuentren en el territorio nacional o que, estando en el extranjero, solicitan la gestión de un encargo o servicios, que debe ser realizado en territorio boliviano y dentro del radio o alcance de el territorio que se encuentra predeterminado por el PROVEEDOR, pagando una contraprestación económica, en dinero en efectivo al momento de la recepción de los productos o finalizacion del servicio, a elección del Consumidor.<br></br>
<br></br>
            A través de la Plataforma se exhiben productos, bienes y servicios, cuya gestión de compra es encargada por los Consumidores a los Mandatarios, siendo que los primeros buscan satisfacer una necesidad privada, personal o familiar, y en ningún momento pretenden la comercialización, reventa o cualquier otro tipo de transacción comercial o interés con los productos adquiridos.<br></br>
<br></br>
            PLATAFORMA TECNOLÓGICA QUE PERMITE SU USO<br></br>
<br></br>
            Es una plataforma que permite su uso gratuito por varios medios, a saber: i) portal web: www.teayudo.com.bo, y ii) aplicación que se puede descargar en dispositivos móviles por medio de tiendas virtuales de aplicaciones de Apple (App Store) y de Google (Google Play), medios que en adelante y para los efectos de los presentes términos se denominarán conjuntamente “La Plataforma”. Los Consumidores podrán utilizar La Plataforma exclusivamente para su uso personal, sin que esto implique el otorgamiento de una licencia de la tecnología de la plataforma de ningún tipo.<br></br>
            MODIFICACIÓN<br></br>
            El Operador podrá modificar autónomamente y en cualquier momento en aspectos formales, procedimentales o sustanciales los presentes Términos y Condiciones de uso de La Plataforma, los cuales serán actualizados y puestos a disposición de los Consumidores en La Plataforma, para mejorar el servicio de la misma, siendo la última versión publicada la que regulará las relaciones comerciales que se generen al momento de realizarse la transacción o negocio comercial electronico. Así mismo, cuenta con plena autonomía para modificar los usos y formas de La Plataforma permitidos a los Consumidores, con el único deber de informarlo por un medio virtual que permita su publicación y comunicación al público.<br></br>
<br></br>
            DETALLE DEL SERVICIO<br></br>
<br></br>
            CONSUMIDOR<br></br>
            Toda persona natural que, como destinatario final, use La Plataforma para solicitar por medio de ésta un mandato remunerado, cuyo encargo consiste en la celebración de un contrato de servicio, encargo, compraventa de bienes y productos o cualquier otro tipo de contrato lícito, con el fin de adquirir bienes o servicios.<br></br>
<br></br>
            El uso de la Plataforma lo realiza el Consumidor como persona capaz, manifestando que, para la celebración de contratos de mandato con el Mandatario, cuenta con plena capacidad legal para ser sujeto de derechos y obligaciones, calidades que refrenda al momento de generar su registro.<br></br>
<br></br>
            El Consumidor tiene la obligación de: 1) proveer un documento de identidad nacional (Cédula de Ciudadanía/Extranjería/Pasaporte) original, expedido por el SEGIP en el Estado Plurinacional de Bolivia o por la autoridad competente en su país de origen;<br></br>
<br></br>
            MANDATARIO<br></br>
            Persona natural que acepta realizar la gestión del encargo, servicio, o venta de bieno producto solicitado por el Consumidor a través de La Plataforma, por cuenta y riesgo propio.<br></br>
<br></br>
            CUENTA DE USUARIO<br></br>
            Los Consumidores usan como referencia para el encargo de compra, los productos o servicios que se encuentran exhibidos en La Plataforma, teniendo como condición necesaria la creación de una Cuenta de Usuario, donde se solicitarán datos como nombre, fecha de nacimiento, teléfono, dirección, dirección de correo electrónico y cédula de ciudadanía. Se encuentra prohibido tener más de una (1) cuenta relacionada a cualquiera de los datos anteriormente mencionados. Esta información se utiliza para la plena identificación de las personas que pretenden realizar el encargo al Mandatario y de esta manera adquirir los productos y servicios, para el cumplimiento de los presentes términos y condiciones, para la prevención de fraudes, para vincular al Consumidor con el Mandatario y en general para los fines definidos en el acápite manejo de información.<br></br>
<br></br>
            Podrán los Consumidores, además de la información obligatoria y facultativa requerida al momento de la creación de la cuenta, suministrar voluntariamente más datos relacionados con su individualización al momento en que cree su propio Perfil dentro de La Plataforma.<br></br>
            El uso de las cuentas es personal e intransferible, por lo cual los Consumidores no se encuentran facultados para ceder los datos de validación para el acceso a La Plataforma ni el uso de su cuenta a ningún tipo de terceros. El incumplimiento de lo anterior, acarreará la suspensión y bloqueo definitivo de la cuenta (incluye correo electrónico y número de identificación personal). En caso de olvido de los datos de validación o de usurpación de éstos, es obligación del Consumidor informarlo al Operador a través de la opción “olvidó su contraseña” o a través de comunicación enviada al correo electrónico contacto@teayudo.com.bo.<br></br>
<br></br>
            Las cuentas de los usuarios serán administradas por el Operador o por la persona que éste designe, teniendo plena facultad para la conservación o no de la cuenta, cuando la información suministrada por los Consumidores no sea veraz, completa o segura; o cuando se presente incumplimiento de las obligaciones de los Consumidores.<br></br>
<br></br>
            En ningún momento el Operador solicitará al Consumidor información que NO resulte necesaria para su vinculación con el Mandatario y para la facilitación del pago. Por lo tanto, los datos de tarjetas débito o crédito, solo se solicitarán al momento de realizar el pago virtual si ese es el método de pago deseado por el Consumidor.<br></br>
<br></br>
            Con la creación de la Cuenta de Usuario, los Consumidores están manifestando su voluntad de aceptación expresa e inequívoca de los presentes Términos y Condiciones de uso de La Plataforma, así como de la Política de tratamiento de datos personales de Te Ayudo, por lo que Autoriza expresamente el Consumidor al momento de la aceptación de los presentes Términos, el uso de Cookies por parte del Operador en toda actividad de uso que realice de la Plataforma.<br></br>
<br></br>
            CAPACIDAD<br></br>
            Es claro para el Consumidor que la relación contractual que se puede llegar a generar por el uso de La Plataforma no vincula de ninguna manera al Operador. Lo anterior, puesto que la relación contractual será directamente con el Mandatario, y consistirá en un contrato de mandato remunerado celebrado por medio electrónicos, en el que el Consumidor es el mandante.<br></br>
<br></br>
            En virtud de las condiciones de capacidad legal establecidas en el Código Civil Boliviano, los Consumidores al momento de la creación de la Cuenta de Usuario, manifiestan expresamente tener capacidad para celebrar el tipo de transacciones que se pueden realizar usando La Plataforma; y con base en lo prescrito en la ley del Estado Plurinacional de Bolivia, los menores de edad cuentan con capacidad para celebrar éste tipo de transacciones, no obstante el Operador deberá: i) Excluir del sistema de información los datos de menores de edad que hayan utilizado La Plataforma; ii) Dar a conocer a las autoridades de cualquier situación, de la que tenga conocimiento, que ponga en peligro la integridad de un menor de edad; iii) Informar a los menores que se encuentren interesados en adquirir productos a través de La Plataforma usando medios de pago electrónico, que deberán realizar la transacción económica de carácter electrónico a través de sus padres o representantes legales, previo registro en la plataforma por parte de éstos.<br></br>
<br></br>
            DESCRIPCIÓN DEL SERVICIO<br></br>
            El Operador exhibe a través de La Plataforma productos y servicios en general, que están a disposición de los Consumidores para su conocimiento general. Esta comunicación de productos y servicios sirve como referencia a los Consumidores para el encargo de compraventa, usando La Plataforma como medio para solicitar la gestión de un encargo de compraventa, celebrándose un contrato de mandato remunerado con el Mandatario.<br></br>
            Para el proceso de solicitud de gestión del encargo de compraventa, los Consumidores deben estar plenamente identificados en su Cuenta de Usuario y seguir el siguiente procedimiento:<br></br>
            a) Ingresar a la Plataforma especificando el domicilio para determinar los productos que se encuentran disponibles en este sector, pudiendo cada vez que se utilice adicionar un domicilio pero que igual el contrato será bajo su responsabilidad.<br></br>
            b) Seleccionar el lugar de entrega. Se debe suministrar por el Consumidor la dirección exacta donde se realizará la entrega del (los) producto (s) y servicio (s) seleccionados, esta dirección debe encontrarse en el rango de cobertura de entrega, en caso de no encontrarse en dicho rango no se permitirá la finalización de la transacción.<br></br>
            c) Seleccionar el producto o servicio. Una vez seleccionado se pone a disposición del Consumidor las características y valor total del producto o servicio por medio de fotografías y notas de referencias, que permiten la plena individualización del producto o servicio para el análisis detallado del Consumidor.<br></br>
            d) Validación del producto o servicio. Cumplido el paso anterior, el Operador deberá exhibir al consumidor un resumen del producto o servicios en cuanto a sus condiciones generales tales como la marca y la presentación suministrada. De esta manera el Consumidor podrá validar y confirmar la información y el producto o servicio seleccionado.<br></br>
            e) Ingreso del producto o servicio al carrito de compras. Este ingreso corresponde a la voluntad inequívoca del Consumidor de solicitar la gestión de un encargo consistente en adquirir un producto determinado, ya que se ha informado con suficiencia las características del mismo, teniendo la posibilidad de adquirirlo o no. El Operador tiene total autonomía para limitar el ingreso de productos o servicios al carrito de compras con ocasión a la cantidad.<br></br>
            f) Valor. Una vez se han agotados los pasos precedentes, se pone a disposición del Consumidor el valor a pagar por la gestión del encargo solicitado, consistente en la compra de los productos o servicios seleccionados. Dicho valor refleja: i) remuneración por el mandato (valor del servicio de domicilio); ii) la suma a reembolsar por la gestión, la cual se encuentra discriminada unitariamente y en conjunto por todos los productos, cuya adquisición fue solicitada. El valor a reembolsar corresponde al valor total de los productos o servicios adquiridos, incluyendo costos de transacción e impuestos.<br></br>
            Te Ayudo se reserva el derecho de incrementar, hasta en un diez por ciento (10%), los precios de los servicios o de los productos exhibidos en las tiendas físicas de sus aliados. El usuario o consumidor reconoce y acepta la anterior condición e igualmente tampoco realizará ningún tipo de reclamación frente a Te Ayudo o INVERSIONES PIVES S.R.L. por estos cobros adicionales a los precios que se vean reflejados en facturas de venta. Los valores adicionales serán cobrados a título de “tasa de servicio/service fee/taxa de servicio” por el uso de la plataforma virtual. En caso de requerir la entrega de la factura de compra deberá requerir al Mandatario para que le haga la entrega de ésta.<br></br>
            g) Pago. El Consumidor realizará el pago directamente al Mandatario bien sea en efectivo o a través de las plataformas virtuales de pagos que tiene contratadas el Operador para este fin. La orden/compra se entiende finalizada y por lo tanto realizada, una vez el Mandatario entrega los productos o finalice el servicio al Consumidor y siempre y cuando tramite y finalice la orden.<br></br>
            h) Forma de pago. El Consumidor debe seleccionar el medio de pago que desea utilizar, teniendo como posibilidades el pago contra-entrega a través de i) dinero en efectivo.<br></br>
            Registro. El Usuario (Consumidor) debe crear su cuenta personal en la cual se requerirán los datos personales que permitan su identificación, mas no su individualización.<br></br>
            j) Resumen y Correo electrónico. Una vez completados los pasos anteriores se le exhibe a través de una ventana emergente al Consumidor un resumen detallado de la transacción y la información completa del Mandatario que ha aceptado gestionar el encargo solicitado y con el cual se celebra el contrato de mandato.<br></br>
            k) Resumen del encargo. En todo caso, sea que el pago se realice en dinero en efectivo o a través de la pasarela de pagos, se enviará vía correo electrónico el resumen del encargo solicitado, con el detalle de la transacción. Te Ayudo no emite facturas toda vez que solo es una plataforma virtual que no comercializa productos ni servicios directamente. El vendedor directo de los productos y servicios es el tercero, con quien el Mandatario celebra un contrato por cuenta y riesgo del Consumidor. De esta manera, en caso de requerir la factura de compra de los productos, el usuario deberá informar al Mandatario, bien sea por el chat de la plataforma virtual o por vía telefónica, que desea recibir la factura de los productos que el Mandatario adquirió en cualquier establecimiento de comercio. Te Ayudo no será responsable por la entrega de facturas y el usuario deberá entenderse directamente con el Mandatario, toda vez que es éste quién realiza el encargo solicitado por el Consumidor.<br></br>
            Parágrafo primero. Los servicios prestados por el Mandatario directamente a través del botón de Solicitar Servicio se encuentran excluidos de esta solicitud toda vez que no existe obligación de emitir factura.<br></br>
            l) Entrega. Verificados todos los datos correspondientes a la transacción y al ámbito de cobertura de entrega de productos o servicios, el Mandatario entregará en la dirección suministrada y en el término definido al finalizar la operación, los productos o servicios comprados en virtud del encargo solicitado. En caso de no poderse realizar la entrega por razones imputables al Consumidor, deberá el Mandatario dejar constancia de este hecho.<br></br>
<br></br>
            El perfeccionamiento del contrato de mandato celebrado por medios electrónicos se da en el momento en que el Mandatario entrega el encargo realizado al Consumidor.<br></br>
<br></br>
            DEBERES DEL CONSUMIDOR<br></br>
            Con la aceptación de los presentes términos El Consumidor se obliga a: (1) Suministrar información veraz y fidedigna al momento de crear su Cuenta de Usuario; (2) Abstenerse de transferir a terceros los datos de validación (nombre de usuario y contraseña); (3) Abstenerse de utilizar la Plataforma para realizar actos contrarios a la moral, la ley, el orden público y buenas costumbres en contra del Operador, los Mandatarios o de terceros; (4) Pagar oportunamente al Mandatario la contraprestación económica definida en el contrato de mandato; (5) Informar inmediatamente al Operador en caso de olvido o usurpación de los datos de validación; (6) Abstenerse de realizar conductas atentatorias del funcionamiento de La Plataforma; (7) Abstenerse de suplantar la identidad de otros Consumidores; (8) Abstenerse de descifrar, descompilar o desensamblar cualquier elemento de La Plataforma o de cualquiera de sus partes; (9) Habilitar la utilización de ventanas emergentes durante la operación; (10) En general todas aquellas conductas necesarias para la ejecución del negocio jurídico, como i) la recepción de los productos o servicios solicitados, ii) exhibir la identificación en caso de venta de productos o servicios de uso restringido, iii) verificar al momento de la validación que los productos o servicios seleccionados sí corresponden a los necesitados, iv) informarse sobre las instrucciones de uso y consumo de los productos o servicios.<br></br>
<br></br>
            DEBERES DEL OPERADOR<br></br>
            En virtud de los presentes términos el Operador, se obliga a (1) Suministrar información cierta, fidedigna, suficiente, clara y actualizada respecto de los productos o servicios que exhibe; (2) Indicar las características generales del producto o servicio para que sirvan de referencia a los Consumidores, para el efecto son marca y presentación; (3) Informar suficientemente sobre los medios habilitados para que los Consumidores realicen el pago; (4) Informar en el momento indicado y con suficiencia los datos de los Mandatarios con los cuales los Consumidores han de celebrar el contrato de mandato; (5) Poner a disposición de los Consumidores los términos y condiciones de uso de La Plataforma de forma actualizada; (6) Utilizar la información únicamente para los fines establecidos en los presentes términos; (7) Utilizar mecanismos de información y validación durante la transacción como ventanas emergentes (Pop Ups), que permitan al Consumidor aceptar o no cada paso del proceso de compra.<br></br>
<br></br>
            DESCRIPCIÓN DE LOS PRODUCTOS Y SERVICIOS<br></br>
            Los productos y servicios exhibidos por el Operador son productos y servicios como ser mantenimientos de equipos en el hogar, lavado de vehiculo, jardineria, plomeria, fumigacion, entre otros. Todos los productos y servicios cuentan con una descripción general; esta descripción se realiza por el Operador a partir de prácticas legales de visualización, que en todo caso dependen del dispositivo en donde el Consumidor observe el producto o servicio. La disponibilidad será definida en cada caso en concreto al momento en que el Mandatario gestione el encargo del producto o servicio con Proveedores oficiales. Dentro de la ejecución del contrato de mandato, el Consumidor determinará qué acción debe realizar el Mandatario en caso de no hallar el producto o productos y servicios o servicios solicitados, entre: (i) Cumplir con el pedido excluyendo el producto o productos y servicios o servicios solicitados no hallados, caso en el cual se descontará su valor del valor total del pedido y, en caso de ser sólo un producto o servicio y no se halla, deberá elegir entre la cancelación del pedido o el cumplimiento con uno sustituto o similar en precio y tipo; (ii) Comunicarse con el Consumidor para concertar el cumplimiento con un producto o servicio sustituto; y (iii) Autorizar al Mandatario para llevar un producto o servicio similar en precio y tipo.<br></br>
<br></br>
            Es claro para el Consumidor que el Operador no es productor, proveedor, expendedor, agente, distribuidor y en general ningún tipo de comercializador de los productos y servicios que exhibe, ya que opera solo como una plataforma tecnológica que permite el encuentro de Consumidores y Mandatario para la gestión de encargos.<br></br>
            Los productos de uso restringido, como tabaco y bebidas embriagantes, solo podrán ser adquiridas por el Mandatario, en virtud del encargo realizado por Consumidores que cuenten con mayoría de edad, quienes manifiestan expresamente esta calidad al momento de registrarse en la plataforma o de seleccionar el producto. En caso de no cumplir con esta obligación, el Mandatario no entregará el producto o servicio y en el evento de haber pagado por el producto o servicio, deberá solicitar la devolución de su dinero. En el evento de haber solicitado junto con los productos o servicios de uso restringido otros de diferente calidad e igualmente se incumpla la obligación acá descrita, aplicarán las mismas consecuencias jurídicas aquí definidas, pero solo en relación con los productos de uso restringido.<br></br>
            Cuando por errores de carácter tecnológico se exhiban precios erróneos de los productos en la Plataforma, que evidentemente resultan desproporcionados, comparativamente con el precio del producto en el mercado, el Operador podrá cancelar la(s) ordenes realizadas de productos con dichos precios, a su libre discreción y con plena autonomía.<br></br>
            El Operador se reserva el derecho de actualizar, modificar y/o descontinuar los productos o servicios exhibidos en La Plataforma.<br></br>
<br></br>
            GARANTÍA<br></br>
            Entiende y acepta el Consumidor que la relación jurídica de mandato se genera directamente con los Mandatarios, por lo tanto, las reclamaciones por garantía se deben realizar directamente a los Mandatarios, quienes tienen la obligación de responder por la gestión del encargo.<br></br>
<br></br>
            CONSIDERACIONES FINALES<br></br>
            CONTENIDOS<br></br>
            A través de la Plataforma el Operador podrá poner a disposición de los Consumidores información de carácter comercial y publicitario, propio o de terceros de conformidad a las buenas costumbres comerciales. En estos casos el Operador no avala, garantiza o compromete su responsabilidad frente a los servicios y/o productos que se comercialicen por estos terceros, ya que la Plataforma sirve como canal de comunicación y publicidad, mas no como herramienta de prestación de servicios. En consecuencia, es total responsabilidad de los Consumidores el acceso a los sitios que remite la publicidad, asumiendo la obligación de verificar y conocer los términos de los servicios ofrecidos por los terceros.<br></br>
            Toda la información puesta a disposición en la Plataforma como imágenes, publicidad, nombres, marcas, lemas y demás elementos de propiedad intelectual; son utilizados legítimamente por el Operador, sea porque son de su propiedad, porque tiene autorización para ponerlos a disposición, o porque se encuentra facultado para hacerlo en virtud de las leyes que lo avalan.<br></br>
<br></br>
            FUNCIONAMIENTO DE LA PLATAFORMA<br></br>
            El Operador administra directamente o por medio de terceras personas la Plataforma, toda la información que se comunica allí corresponde a información cierta y actualizada. En ningún caso responderá por daños directos o indirectos que sufra el Consumidor por la utilización o incapacidad de utilización de La Plataforma.<br></br>
            La plataforma se encuentra disponible las 24 horas del día para su acceso y consulta. Para la realización de transacciones la disponibilidad de La Plataforma es de 24 horas al día, dependiendo de la disponibilidad de los Mandatarios. El Operador realiza los mejores esfuerzos para mantener en operación La Plataforma, pero en ningún caso garantiza disponibilidad y continuidad permanente de La Plataforma.<br></br>
            El Operador se reserva el derecho de cancelar las cuentas de usuarios y/o de prohibir el acceso a La Plataforma a los Consumidores que realicen conductas violatorias de los presentes términos o que incumplan las obligaciones contraídas.<br></br>
<br></br>
            DERECHO DE RETRACTO<br></br>
            Debido a que los productos y servicios que se comercializan a través de la Plataforma son productos y servicios perecederos, el Operador informa a los Consumidores que los Mandatarios no se encuentran obligados a otorgar el derecho de retracto.<br></br>
            El derecho de retracto no aplica en razón a los servicios prestados por los Mandatarios.<br></br>
<br></br>
            COMERCIO ELECTRÓNICO<br></br>
            En cumplimiento de las disposiciones bolivianas sobre mensajes de datos, se comunica que la legislación nacional reconoce validez a los mensajes de datos y por tanto ellos adquieren carácter y entidad probatoria. En consecuencia, entienden los Consumidores, que mediante el cruce de mensajes de datos los intervinientes pueden dar lugar al nacimiento, modificación y extinción de obligaciones, siendo de su resorte exclusivo el contenido, consecuencias, responsabilidades y efectos de la información generada.<br></br>
            La transacción comercial que nace por este medio entre Consumidores y Mandatarios, es la celebración de un contrato de mandato por medios electrónicos, el cual se describe en la ventana emergente que acepta el Consumidor al momento de la celebración del negocio jurídico, en ningún momento se configura relación contractual diferente como suministro, distribución o demás similares.<br></br>
<br></br>
            MANEJO DE INFORMACIÓN<br></br>
            La información recolectada por el Operador para la solicitud del encargo, es suministrada por los Consumidores de forma libre y voluntaria, para que esta sea administrada por el Operador o por quien éste designe para el cumplimiento de los deberes adquiridos, lo que implica su recolección; almacenamiento en servidores o repositorios del Operador o de terceros; circulación de los mismos dentro de la organización del Operador; comunicación a los Consumidores información comercial, publicitaria y de mercadeo relacionada con su actividad comercial.<br></br>
            Así mismo, los datos recolectados serán objeto de análisis para fines de mejorar la estrategia de negocios del portal web, apoyada en herramientas de inteligencia de negocios y minería de datos, que permiten adquirir conocimientos prospectivos para fines de predicción, clasificación y segmentación.<br></br>
<br></br>
            PROPIEDAD<br></br>
            El título, los derechos de propiedad y los derechos de propiedad intelectual en y relativos al Software o PLATAFORMA de EL OPERADOR seguirán perteneciendo a INVERSIONES PIVES S.R.L. o sus licenciantes y proveedores. El Software está protegido por la Ley de Derechos de Autor Bolivianas, las leyes y los tratados internacionales de derechos de autor, así como otras leyes y tratados de propiedad intelectual. No se le transfiere a usted ningún título o propiedad del Software o LA PLATAFORMA y esta Licencia no se interpretará como una venta de ningún derecho en el Software.<br></br>
            Usted se compromete a no eliminar ni alterar ningún aviso de derechos de autor, marca comercial, marca registrada y otros avisos de propiedad en ninguna copia del Software. TEAYUDO y/o INVERSIONESPIVES S.R.L. y/o sus licenciantes y proveedores se reservan todos los derechos no otorgados. El Software también puede contener imágenes, ilustraciones, diseños y fotografías (“Materiales”), y los derechos de autor de dicho material pertenecen a TEAYUDO y/o INVERSIONESPIVES S.R.L. y/o a sus licenciantes y proveedores según el caso, y están protegidos por leyes, convenciones y tratados de propiedad intelectual nacionales o internacionales. Para mayor claridad, (1) los Materiales se utilizarán únicamente para fines no comerciales, (2) los Materiales se editarán, ajustarán y copiarán solo de la manera designada por el Software, y (3) usted puede usar los Materiales solo para uso personal lícito, uso en el hogar o según lo legalmente permitido.<br></br>
<br></br>
            LIMITACIÓN DE RESPONSABILIDAD.<br></br>
            EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY APLICABLE, TEAYUDO Y/O INVERSIONESPIVES NO SERÁN RESPONSABLES EN NINGÚN CASO POR DAÑOS, YA SEAN DIRECTOS, INDIRECTOS, ESPECIALES, INCIDENTALES O EMERGENTES, QUE SURJAN EN VIRTUD DE UN CONTRATO, AGRAVIO (INCLUIDA NEGLIGENCIA), RESPONSABILIDAD ESTRICTA, INCUMPLIMIENTO DE GARANTÍA, DECLARACIÓN FALSA O DE OTRO TIPO, INCLUIDOS, ENTRE OTROS, DAÑOS POR LUCRO CESANTE, INTERRUPCIÓN COMERCIAL, PÉRDIDA DE INFORMACIÓN DEL NEGOCIO U OTRA PÉRDIDA PECUNIARIA, QUE SE DERIVEN DEL USO O DE LA INCAPACIDAD DE USAR EL SOFTWARE, O QUE SE DERIVEN DE ESTE ACUERDO, INCLUSO SI ELOPERADOR O SU REPRESENTANTE HAN SIDO AVISADOS DE LA POSIBILIDAD DE DICHOS DAÑOS.<br></br>
<br></br>
            TOTALIDAD DEL ACUERDO<br></br>
            Este Acuerdo es el acuerdo completo entre las partes relacionado con el Software o Plataforma y reemplaza a cualquier orden de compra, comunicación, publicidad o declaración relacionada con el Software o la aplicación de la plataforma.<br></br>
<br></br>
            ACUERDO VINCULANTE – CESIONARIOS<br></br>
            Este Acuerdo será vinculante y redundará en beneficio de las partes y sus respectivos sucesores, cesionarios y representantes legales.<br></br>
<br></br>
            DIVISIBILIDAD – MODIFICACIONES<br></br>
            Si alguna de las disposiciones de este documento es considerada nula o inaplicable por un tribunal de jurisdicción competente, ello no afectará la validez del resto del Acuerdo, que permanecerá vigente y ejecutable de conformidad con sus términos. Este Acuerdo solo puede ser modificado por escrito y firmado por un representante autorizado de INVERSIONES PIVES S.R.L.<br></br>
<br></br>
            RESCISIÓN<br></br>
            Sin perjuicio de cualquier otro derecho que tenga TE AYUDO y/o INVERSIONESPIVES S.R.L.n, sus derechos de licencia y sus derechos de garantía se extinguirán automáticamente si usted no cumple con este Acuerdo. Al extinguirse dichos derechos, acepta que no esta habilitado para el uso de la aplicación y/o plataforma.<br></br>
<br></br>
            CAPACIDAD Y AUTORIDAD PARA CELEBRAR CONTRATOS<br></br>
            Usted declara que tiene la mayoría de edad legal en dentro del estado Plurinacional de Bolivia y tiene toda la autoridad necesaria para celebrar este Acuerdo, incluida, si corresponde, la debida autorización de su empleador para celebrar este Acuerdo.<br></br>
<br></br>
            ESTE ES UN ACUERDO LEGAL (“ACUERDO”) ENTRE USTED (UN PARTICULAR O ENTIDAD, DENOMINADO EN ADELANTE “USTED”) Y “TEAYUDO” Y/O “INVERSIONESPIVES S.R.L.” (INCLUIDAS SUS AFILIADAS O SUCURSALES) PARA LOS PROGRAMAS DE LA PLATAFORMA ADJUNTOS, INCLUIDA CUALQUIER DOCUMENTACIÓN, FIRMWARE O ACTUALIZACIONES RELACIONADAS (EN LO SUCESIVO, DENOMINADOS COLECTIVAMENTE LA “PLATAFORMA”). TE AYUDO Y/O INVERSIONESPIVES S.R.L. Y SUS PROVEEDORES PROPORCIONAN EL SOFTWARE PARA SU USO EXCLUSIVO. ANTES DE INSTALAR, O UTILIZAR LA PLATAFORMA O SOFTWARE, DEBE REVISAR Y ACEPTAR LOS TÉRMINOS Y CONDICIONES DE ESTE ACUERDO, INCLUIDA LA POLÍTICA DE PRIVACIDAD.<br></br>
            SI ACEPTA, HAGA CLIC EN EL BOTÓN ACEPTO (“ACEPTAR”, “OK” O CUALQUIER OTRA REPRESENTACIÓN SIMILAR DE ACUERDO) QUE VERÁ A CONTINUACIÓN. SI NO ACEPTA LOS TÉRMINOS Y CONDICIONES DE ESTE ACUERDO, HAGA CLIC EN EL BOTÓN NO ACEPTO (“SALIR”, “CANCELAR” O CUALQUIER OTRA DECLARACIÓN SIMILAR DE DESACUERDO) NO PUDIENDO ACCEDER AL USO DE LA PLATAFORMA.


        </div>
    )
}

export default TyC

