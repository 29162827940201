import React, { useCallback, useEffect, useState } from 'react';
import { useListContext, Datagrid, TextField, FunctionField, DateField, Loading,} from 'react-admin';
import { CircularProgress, IconButton, Link, Link as MuiLink, TextField as MuiTextField } from '@mui/material';
import moment from 'moment';
import { useIntersectionObserver } from './useIntersectionObserver';
import debounce from 'just-debounce-it';
import { Article } from "@mui/icons-material";
const WhatsAppField = ({ record }) => {
  if (!record || !record.phone) return null;
  return (
    <MuiLink href={`https://wa.me/${record.countryCode}${record.phone}`} target="_blank" rel="noreferrer" color="primary">
      {record.phone}
    </MuiLink>
    // <a href={`https://wa.me/${record.countryCode}${record.phone}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }}>
    //   {record.phone}
    // </a>
  );
};

const ServicesButton = ({ record }) => {
  return(
    <Link href={"#/enterprise/customers/" + record.id + "/services"} style={{display: "flex", alignItems: "center"}}>
      <IconButton size="medium" color="primary">
        <Article fontSize="medium" />
      </IconButton>
      {/* <Typography variant="body2" color="primary">Ver</Typography> */}
    </Link>
  );
}

const InfiniteListLayout = ({ setTotalCount, setTotalPages }) => {
  const {
    data,
    total,
    page,
    setPage,
    isLoading,
    hasNextPage,
    sort
  } = useListContext();
  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, []);
  const [details, setDetails] = useState({});
  const [allRecords, setAllRecords] = useState([]);
  const [historial, setHistorial] = useState({});
  const profile = JSON.parse(localStorage.getItem("profile"));
  useEffect(() => {
    if (!data) return;
    if (page === 1) {
      setAllRecords(data);
    } else {
      setAllRecords(prev => {
        const existingIds = new Set(prev.map(r => r.id));
        const newRecords = data.filter(r => !existingIds.has(r.id));
        const combined = [...prev, ...newRecords];
        return combined;
      });
    }
    setTotalCount(total);
  }, [ page, data ]);

  setTotalPages(allRecords.length);
  const canLoadMore = allRecords.length < total;
  const [sentinelRef, isIntersecting] = useIntersectionObserver({
    rootMargin: '0px',
    threshold: 0.1,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleNextPage = useCallback(debounce(
    () => setPage(page+1), 1000
  ), [setPage])
  useEffect(() => {
    if (isIntersecting && canLoadMore && hasNextPage) {
      debounceHandleNextPage()
    }
  }, [isIntersecting, canLoadMore, debounceHandleNextPage, hasNextPage]);
  
  const handleDetailChange = (id, value) => {
    setDetails(prev => ({ ...prev, [id]: value }));
  };

  const handleDetailBlur = async(record) => {
    const detail = details[record._id];
    if (!detail) return;
    const customer = allRecords.find(c => c._id === record._id);
    if (!customer) return;
    const updatedCustomer = { ...customer, detail };
    // console.log(updatedCustomer)   
    
    if(updatedCustomer.countryCode === undefined){
      updatedCustomer.countryCode = "591"
    } 
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_API_URL}/enterprise/customers/${record._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedCustomer)
      });
      
      if (!response.ok) {
        throw new Error("Error al actualizar el cliente");
      }
      console.log(`Cliente ${record._id} actualizado con detalle:`, detail);
    } catch (error) {
      console.error("Error al actualizar detalle del cliente:", error);
    }
  };
  useEffect(() => {
      if (!allRecords.length) return;

      // Evitar llamadas innecesarias comparando el estado anterior de los clientes
      const customersString = JSON.stringify(allRecords.map(c => c.id)); 

      if (customersString === sessionStorage.getItem("prevCustomers")) return;
      sessionStorage.setItem("prevCustomers", customersString);

      const fetchHistorial = async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/booking/customer/historial${profile.branch === undefined ? '' : `?branch._id=${profile.branch._id}`}`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ customers: allRecords })
          });

          if (!response.ok) {
            throw new Error('Error al obtener historial');
          }

          const result = await response.json();
          const historialMap = result.data.reduce((acc, customer) => {
            acc[customer.id] = customer.historial.length;
            return acc;
          }, {});

          setHistorial(historialMap);
        } catch (error) {
          console.error('Error al obtener historial de reservas:', error);
        }
      };

      fetchHistorial();
  }, [allRecords]);
  useEffect(() => {
    if (!sort || !sort.field) return;

    setAllRecords((prev) => {
      const sorted = [...prev].sort((a, b) => {
        let aValue, bValue;

        if (sort.field === 'daysSinceLastService') {
          aValue = a.lastBooking?.bookingDate 
            ? moment().diff(moment(a.lastBooking.bookingDate), 'days') 
            : 0; // Mover los vacíos al final
          bValue = b.lastBooking?.bookingDate 
            ? moment().diff(moment(b.lastBooking.bookingDate), 'days') 
            : 0;
        } else if (sort.field === 'historial') {
          aValue = historial[a.id] ?? 0;
          bValue = historial[b.id] ?? 0;
        } else {
          aValue = a[sort.field] ?? 0;
          bValue = b[sort.field] ?? 0;
        }

        return sort.order === 'ASC' ? aValue - bValue : bValue - aValue;
      });

      return sorted;
    });
  }, [sort, historial]);
  return (
    <div style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
      <Datagrid
        bulkActionButtons={false}
        data={allRecords}
        isLoading={isLoading}
        rowClick="edit"
        sx={{
          "& .RaDatagrid-headerCell": {
            maxWidth: "100px", // Ancho máximo del encabezado
            whiteSpace: "normal", // Permite el salto de línea en el texto del encabezado
          },
          "& .RaDatagrid-cell": {
            textAlign: "center", // Centra el contenido de las celdas
          },
        }}
      >
        <TextField source="fullName" label="Nombre Completo" />
        <FunctionField label="Teléfono" render={(record) => <WhatsAppField record={record} />} />
        {/* <TextField source="email" label="Email" /> */}
        <TextField source="gender" label="Género" />
        <DateField source="birthdate" label="Fecha de Nacimiento" />
        <FunctionField
          label="Fecha de Último Servicio"
          sortBy="lastBooking.bookingDate"
          render={(record) => {
            const date = record.lastBooking ? moment(record.lastBooking.bookingDate) : null;
            return (
              <div style={{ maxWidth: "100px", textAlign: "center", overflow: "hidden", whiteSpace: "nowrap" }}>
                {record.lastBooking?.bookingDate ? (
                  <Link href={"#/booking/" + record.lastBooking._id + "/show"}>
                    {date.format("DD/MM/YYYY")}
                  </Link>
                ) : (
                  "-"
                )}
              </div>
            );
          }}
        />
        <FunctionField
          label="Dias desde el ultimo servicio"
          sortBy="daysSinceLastService"
          render={(record) => {
            return (
              <div style={{ maxWidth: "100px", textAlign: "center", overflow: "hidden", whiteSpace: "nowrap" }}>
                  {record.lastBooking?.bookingDate ? record.daysSinceLastService : "-"}
              </div>
            )
          }}
        />
        <FunctionField
          label="Servicios realizados"
          sortBy="historial"
          render={(record) => {
            historial[record.id] ?? <CircularProgress size={20} />
            return (
              <div style={{ maxWidth: "100px", textAlign: "center", overflow: "hidden", whiteSpace: "nowrap" }}>
                {historial[record.id] ?? <CircularProgress size={20} />}
              </div>
            );
          }
          }
        />
        <FunctionField label="Historial" render={(record) => <ServicesButton record={record} />} />
        <FunctionField 
          label="Detalle" 
          render={(record) => (
            <MuiTextField 
              variant="outlined" 
              size="small" 
              fullWidth 
              value={details[record.id] !== undefined ? details[record.id] : record.detail || ''}
              onChange={(e) => handleDetailChange(record.id, e.target.value)}
              onBlur={() => handleDetailBlur(record)}
              onClick={(e) => e.stopPropagation()} 
              placeholder='Agregar detalle'
            />
          )} 
        />
      </Datagrid>

      {isLoading && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Loading />
        </div>
      )}

      {!isLoading && !canLoadMore && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <p>Todos los clientes han sido cargados.</p>
        </div>
      )}
      
      {canLoadMore && !isLoading && hasNextPage &&(
        <div
          ref={sentinelRef} 
          style={{ border: '1px solid transparent', padding: 0, marginTop: 0 }}
        />
      )}
      
      {canLoadMore && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <CircularProgress />
        </div>     
      )}
    </div>
  );
};

export default InfiniteListLayout;
