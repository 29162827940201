import { Alert } from "@mui/material";
import { onMessage } from "firebase/messaging";
import React, { useEffect } from "react";
import { Layout, useNotify } from "react-admin";
import { Link } from "react-router-dom";
import Firebase, { messaging } from "./Firebase/Firebase";
import MyAppBar from "./MyAppBar";
import { CustomMenu } from "./components/CustomMenu";

const MyLayout = (props) => {
  const notify = useNotify();
  useEffect(() => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        notify(
          <Link to={payload.data.url}>
            <Alert severity="info">
              <strong>{payload.data.title}</strong>
            </Alert>
          </Link>
        );
      });
    }
  }, [messaging]);

  return <Layout {...props} appBar={MyAppBar} menu={CustomMenu}></Layout>;
};

export default MyLayout;
