import React, { useState } from "react";
import {
  required,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  NumberField,
  Edit as BaseEdit,
  BooleanInput,
  FunctionField,
  Button,
  SimpleShowLayout,
  useRecordContext,
} from "react-admin";
import {
  TabbedForm,
  FormTab,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { toHoursAndMinutes } from "../../util";
import { UseEmployee } from "../../Booking/Show";
import Map from "../../components/Map";

const ProviderInfo = (props) => {
  const record = useRecordContext();
  return (
    <FormTab label="Información del Proveedor" {...props}>
      <SimpleShowLayout>
        <TextField source="provider.name" label="Nombre de Empresa" />
        {record?.provider?.phone && (
          <TextField source="provider.phone" label="Teléfono" />
        )}
        {record?.provider?.email && (
          <TextField source="provider.email" label="Email" />
        )}
      </SimpleShowLayout>
    </FormTab>
  );
};

const BookingInfo = (props) => {
  const record = useRecordContext();
  let [employee, setEmployee] = useState(null);
  const redirect = useRedirect();
  const notify = useNotify();
  const [update, { loading }] = useUpdate();

  const confirmBooking = (record) =>
    update(
      "booking",
      {
        id: record._id,
        data: {
          employee: {
            _id: employee._id,
            fullName: employee.first_name + " " + employee.last_name,
            phone: employee.phone,
            photoURL: employee.picture,
            pushToken: employee.pushToken,
            CI: employee.CI_NIT,
            location: [],
          },
        },
        meta: { customAction: "confirm" },
      },
      {
        onSuccess: ({ data }) => {
          redirect("/admin/booking");
          notify("Empleado asignado con éxito");
        },
        onFailure: (error) =>
          notify(`Error al asignar empleado: ${error}`, "warning"),
      }
    );

  const assignEmployee = (e, record) => {
    e.preventDefault();
    if (employee) {
      return confirmBooking(record);
    } else {
      return notify("Asignar al empleado.", "warning");
    }
  };

  const cancel = () =>
    update(
      "booking",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toCancelledByProvider" },
      },
      {
        onSuccess: ({ data }) => {
          redirect("/booking");
          notify("Reserva cancelada con éxito");
        },
        onFailure: (error) =>
          notify(`Error al cancelar reserva: ${error}`, "warning"),
      }
    );

  const submitCancel = (e) => {
    e.preventDefault();
    cancel();
  };

  return (
    <FormTab label="Información de la Reserva" {...props}>
      <SimpleShowLayout>
        <TextField source="status" label="Estado" />
        <DateField
          showTime
          source="bookingDate"
          label="Fecha de Inicio"
          locales="es-ES"
        />
        {record?.serviceCart && (
          <ArrayField source="serviceCart" label="Servicios Adquiridos">
            <Datagrid bulkActionButtons={false}>
              <TextField source="service.name" label="Nombre" />
              <NumberField source="quantity" label="Cantidad" />
              <NumberField source="service.unitPrice" label="Precio Unitario" />
              <NumberField source="price" label="Subtotal (Bs)" />
              <FunctionField
                label="Tiempo Estimado Unitario"
                textAlign="center"
                render={({ service }) =>
                  toHoursAndMinutes(service.unitEstimatedWorkMinutes)
                }
              />
              <FunctionField
                label="Tiempo Estimado Subtotal"
                textAlign="center"
                render={({ estimatedWorkMinutes }) =>
                  toHoursAndMinutes(estimatedWorkMinutes)
                }
              />
            </Datagrid>
          </ArrayField>
        )}
        <TextField source="paymentInfo.totalPrice" label="Precio Total (Bs)" />
        <TextField source="paymentInfo.paymentMethod" label="Método de Pago" />
        <TextField source="paymentInfo.status" label="Estado de Pago" />
        {record?.feedbackInfo?.rating && (
          <TextField source="feedbackInfo.rating" label="Rating" />
        )}
      </SimpleShowLayout>
      {record &&
        (record.status === "Pendiente" ||
          (record.status === "Confirmado" && !record.employee)) && (
          <React.Fragment>
            <UseEmployee setEmployee={setEmployee} record={record} />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => assignEmployee(e, record)}
              size="small"
            >
              <>Asignar Empleado</>
            </Button>
          </React.Fragment>
        )}

      <br />
      <BooleanInput
        validate={[required()]}
        source="paymentInfo.isFeePaid"
        label="Comisión Pagada"
      />
      {record &&
        (record.status === "Pendiente" ||
          record.status === "En proceso" ||
          record.status === "Confirmado") && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={submitCancel}
          >
            Cancelar Reserva
          </Button>
        )}
    </FormTab>
  );
};

const CustomerInfo = (props) => {
  const record = useRecordContext();
  return (
    <FormTab label="Información del Cliente" {...props}>
      <Grid container spacing={3} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <h2 style={{ textAlign: "center" }}>{record?.customer.fullName}</h2>
        </Grid>
        <Grid item xs={12} md={12}>
          <h3>Dirección del cliente</h3>
          <Map
            source={"customer.address.coordinates"}
            initialLatitude={record?.customer.address.coordinates.latitude}
            initialLongitude={record?.customer.address.coordinates.longitude}
            disabled
          />

          <span className="MuiTypography-root MuiTypography-body2">
            {record?.customer.address.name}
            {record?.customer.address.street} #{record?.customer.address.house},{" "}
            {record?.customer.address.reference}
          </span>

          {record?.billingInfo?.businessName && (
            <div>
              <h3>Datos de Facturación</h3>
              <span className="MuiTypography-root MuiTypography-body2">
                {record?.billingInfo.businessName}, NIT:
                {record?.billingInfo.NIT}
              </span>
            </div>
          )}
          {record?.customer?.phone && (
            <div>
              <h3>Teléfono</h3>
              <span className="MuiTypography-root MuiTypography-body2">
                {record?.customer?.phone}
              </span>
            </div>
          )}
        </Grid>
      </Grid>
      <br></br>
    </FormTab>
  );
};

export const Edit = (props) => {
  return (
    <BaseEdit>
      <TabbedForm>
        <BookingInfo />
        <ProviderInfo />
        <CustomerInfo />
      </TabbedForm>
    </BaseEdit>
  );
};
