import React, { useState } from "react";
import { useNotify, Notification, useCreate } from "react-admin";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Background from "./assets/bg-desktop.png";
import avatarLogo from "./assets/avatar.png";
import { StyledEngineProvider } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    minWidth: 300,
    width: 300,
    backgroundColor: "transparent",
  },
  avatar: {
    margin: "1em",
    textAlign: "center ",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    display: "flex",
    marginBottom: 15,
  },
  hint: {
    textAlign: "center",
    marginTop: "1em",
    color: "#ccc",
  },
};
const ResetPasswordPage = (props) => {
  const muiTheme = createTheme({
    palette: {
      primary: { main: "#FF770D" },
      secondary: { main: "#252526" },
    },
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const notify = useNotify();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const [register, { isLoading }] = useCreate();
  const submit = (e) => {
    e.preventDefault();
    if (password && confirmPassword) {
      if (password == confirmPassword) {
        register(
          "resetPassword",
          {
            data: {
              newPassword: password,
              verifyPassword: confirmPassword,
              token: query.get("token"),
            },
          },
          {
            onSuccess: ({ data }) => {
              setSuccess(true);
              setConfirmPassword("");
              setPassword("");
              notify("Contraseña reestablecida con éxito.");
            },
            onFailure: (error) => notify(`${error}`, "warning"),
            onError: (error) => notify(`${error}`, "warning"),
          }
        );
      } else {
        notify("Las contraseñas debe coincidir", "warning");
      }
    } else {
      notify("Debes escribir tu contraseña", "warning");
    }
  };
  const navigate = useNavigate();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <div
          style={{
            ...styles.main,
            backgroundColor: "#252526",
            backgroundImage: `url(${Background})`,
            backgroundSize: "auto",
          }}
        >
          <Card style={styles.card}>
            <div style={styles.avatar}>
              <Avatar
                backgroundColor="transparent"
                src={avatarLogo}
                variant="square"
                style={{ height: 300, width: 225, borderRadius: 0 }}
              />
            </div>
            <form onSubmit={submit}>
              <div style={styles.form}>
                <div style={styles.input}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Nueva Contraseña"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div style={styles.input}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Confirmar Contraseña"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  backgroundColor="#FF770D"
                  style={{ backgroundColor: "#FF770D", marginBottom: 15 }}
                  fullWidth
                  disabled={isLoading || success}
                >
                  Reestablecer
                </Button>
                <Button
                  variant="contained"
                  backgroundColor="black"
                  labelColor="#FF770D"
                  style={{ borderColor: "#FF770D", marginBottom: 15 }}
                  label="Atrás"
                  fullWidth
                  onClick={() => {
                    navigate(process.env.PUBLIC_URL + "/#/login");
                  }}
                >
                  Atrás
                </Button>
              </CardActions>
            </form>
          </Card>
          <Notification />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ResetPasswordPage;
