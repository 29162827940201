import { useDataProvider } from "react-admin";
import { useState } from "react";

const useExport = (resourceName) => {
  const dataProvider = useDataProvider();
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getExportData = async (filters = {}) => {
    setLoading(true);
    const allData = [];
    let page = 1;
    const perPage = 1000;

    try {
      let response;
      do {      
        response = await dataProvider.getList(resourceName, {
          pagination: { page, perPage },
          sort: { field: "id", order: "ASC" },
          filter: filters,
        });
        allData.push(...response.data);
        page++;
      } while (response.total > allData.length);
      
      const shouldTransformServices = resourceName === "coupon" && allData.some((item) => item.validServices && item.validServices.length > 0);

      console.log("¿Es necesario transformar los servicios?", shouldTransformServices);

      if (shouldTransformServices) {
        const validServiceIds = Array.from(new Set(allData.flatMap((item) => item.validServices)));

        console.log("IDs de servicios válidos:", validServiceIds);

        if (validServiceIds.length > 0) {          
          const serviceResponse = await dataProvider.getMany("service", {
            ids: validServiceIds,
          });

          console.log("Respuesta de getMany:", serviceResponse.data); 

          const servicesById = Object.fromEntries(serviceResponse.data.map((service) => [service.id, service.name]));

          console.log("Mapa de servicios por ID:", servicesById); 
          
          const exportDataWithServiceNames = allData.map((item) => ({
            ...item,
            validServices: item.validServices
              .map((serviceId) => {
                const serviceName = servicesById[serviceId];
                if (!serviceName) {
                  console.error(`Servicio no encontrado para ID: ${serviceId}`);
                }
                return serviceName; 
              })
              .filter(Boolean)
              .join(", "),
          }));

          setExportData(exportDataWithServiceNames);
        } else {
          setExportData(allData);
        }
      } else {        
        setExportData(allData);
      }
    } catch (error) {
      console.error("Error al obtener los datos de exportación", error);
    } finally {
      setLoading(false);
    }
  };

  return { getExportData, exportData, loading, setExportData };
};

export default useExport;
