import { useEffect, useRef, useState } from 'react';

/**
 * useIntersectionObserver
 *
 * Hook que controla la intersección de un elemento DOM con su contenedor o viewport
 * usando la API Intersection Observer.
 *
 * @param {Object} options
 * @param {Element|Document|null} [options.root=null]
 * @param {string} [options.rootMargin='0%']
 * @param {number|number[]} [options.threshold=0]
 * @param {boolean} [options.freezeOnceVisible=false]
 * @param {boolean} [options.initialIsIntersecting=false]
 * @param {(isIntersecting: boolean, entry: IntersectionObserverEntry) => void} [options.onChange]
 * @returns {[refCallback: Function, isIntersecting: boolean, entry: IntersectionObserverEntry|undefined] & { ref: Function, isIntersecting: boolean, entry: IntersectionObserverEntry|undefined }}
 *
 * Ejemplo de uso:
 * const [setRef, isIntersecting, entry] = useIntersectionObserver({ threshold: 0.5 });
 * ...
 * <div ref={setRef}>Observa este elemento</div>
 */
export function useIntersectionObserver({
  root = null,
  rootMargin = '0%',
  threshold = 0,
  freezeOnceVisible = false,
  initialIsIntersecting = false,
  onChange,
} = {}) {
  const [ref, setRef] = useState(null);
  const [state, setState] = useState({
    isIntersecting: initialIsIntersecting,
    entry: undefined,
  });

  const callbackRef = useRef(onChange);
  callbackRef.current = onChange;

  // Si el elemento ya es visible y freezeOnceVisible = true, no volverá a cambiar
  const frozen = state.entry?.isIntersecting && freezeOnceVisible;

  useEffect(() => {
    if (!ref) return;
    if (!('IntersectionObserver' in window)) return;
    if (frozen) return;

    const observer = new IntersectionObserver(entries => {
      // En algunos navegadores, thresholds es un array; en otros, un único número
      const thresholds = Array.isArray(observer.thresholds)
        ? observer.thresholds
        : [observer.thresholds];

      entries.forEach(entry => {
        const isIntersecting =
          entry.isIntersecting &&
          thresholds.some(th => entry.intersectionRatio >= th);

        setState({ isIntersecting, entry });

        if (callbackRef.current) {
          callbackRef.current(isIntersecting, entry);
        }
      });
    }, {
      root,
      rootMargin,
      threshold,
    });

    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [
    ref,
    JSON.stringify(threshold),
    root,
    rootMargin,
    frozen,
    freezeOnceVisible,
  ]);

  // Si el elemento observado cambia y no está en freeze,
  // restablecemos el estado inicial
  const prevRef = useRef(null);
  useEffect(() => {
    if (
      !ref &&
      state.entry?.target &&
      !freezeOnceVisible &&
      !frozen &&
      prevRef.current !== state.entry.target
    ) {
      prevRef.current = state.entry.target;
      setState({ isIntersecting: initialIsIntersecting, entry: undefined });
    }
  }, [ref, state.entry, freezeOnceVisible, frozen, initialIsIntersecting]);

  const result = [
    setRef,
    !!state.isIntersecting,
    state.entry,
  ];

  result.ref = result[0];
  result.isIntersecting = result[1];
  result.entry = result[2];

  return result;
}
