import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useInput, FieldTitle } from "react-admin";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormHelperText, TextField } from "@mui/material";
import { es } from "date-fns/locale";
import { useSearchParams } from "react-router-dom";

const Picker = ({ PickerComponent, setIsDateBlocked, ...fieldProps }) => {
  const {
    options,
    label,
    source,
    resource,
    className,
    isRequired,
    shouldDisableDate,
    event
  } = fieldProps;
  const { field, fieldState } = useInput({ source, defaultValue: event?.start ? new Date(event.start).toISOString() : null });


  const { isTouched, error } = fieldState;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDateBlockedLocal, setIsDateBlockedLocal] = useState(false);
  const handleChange = useCallback((value) => {
    if (Date.parse(value)) {
      const isBlocked = shouldDisableDate && shouldDisableDate(value);
      setIsDateBlockedLocal(isBlocked); // Estado local
      setIsDateBlocked?.(isBlocked); // Prop recibida
      field.onChange(isBlocked ? null : value.toISOString());
    } else {
      setIsDateBlockedLocal(false);
      setIsDateBlocked?.(false);
      field.onChange(null);
    }
  }, [shouldDisableDate, setIsDateBlocked]);

  return (
    <div className="picker">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <DesktopDatePicker
          {...options}
          format="dd/MM/yyyy"
          shouldDisableDate={shouldDisableDate}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          margin="normal"
          // error={!!(isTouched && error)}
          error={!!(isTouched && (error || isDateBlockedLocal))}
          helperText={isTouched && error}
          className={className}
          value={
            field.value
              ? new Date(field.value)
              : searchParams.get("date")
              ? new Date(searchParams.get("date"))
              : null
          }
          onChange={(date) => handleChange(date)}
          onBlur={() =>
            field.onBlur(
              field.value ? new Date(field.value).toISOString() : null
            )
          }
          // renderInput={(params) => <TextField {...params} fullWidth/>}
          renderInput={(params) => (
            <div>
              <TextField {...params} fullWidth error={!!(isTouched && (error || isDateBlockedLocal))}/>
              {isDateBlockedLocal && (
                <FormHelperText error>
                  Este empleado no tiene horarios disponibles
                </FormHelperText>
              )}
            </div>
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

Picker.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  shouldDisableDate: PropTypes.func,
};

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  resource: "",
  source: "",
  labelTime: "",
  className: "",
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export const DateInput = ({ setIsDateBlocked, ...props }) => (
  <Picker PickerComponent={DesktopDatePicker} setIsDateBlocked={setIsDateBlocked} {...props} />
);