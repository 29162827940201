import React, { useEffect, useState } from "react";
import { Create as BaseCreate, SimpleForm, SelectInput, required, useGetOne, ReferenceInput, TextInput, usePermissions, useCreate, useChoicesContext, useNotify, useLoading, minValue, useDataProvider, useRedirect } from "react-admin";
import moment from "moment";
import { es } from "date-fns/locale";
import { useFormContext, useWatch } from "react-hook-form";
import { DateInput } from "../components/DatePicker";
import { useSearchParams } from "react-router-dom";

const _DateInput = ({ availability, blockedDates, ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const button = searchParams.get("type");
  const [branchAvailability, setBranchAvailability] = useState(null);
  const { watch, setValue } = useFormContext();

  // useEffect(() => {
  //   // Si la fecha de inicio cambia resetear el valor de la fecha final
  //   if (watch("startDate")) {
  //     setValue("endDate", undefined);
  //   }
  // }, [watch("startDate")]);

  useEffect(() => {
    if (watch("startDate") && (watch("endDate")) && watch("startDate") > watch("endDate")) {
      setValue("endDate", watch("startDate"));
    }
  }, [watch("startDate")]);

  useEffect(() => {
    if (button === "sobreHorario") {
      // Aquí usamos el dataProvider para obtener la disponibilidad de la sucursal
      fetchAvailabilityData();
    }
  }, [button]);

  const fetchAvailabilityData = async () => {
    const token = localStorage.getItem("token"); // Obtener el token del localStorage

    if (!token) {
      console.error("Token no encontrado en el localStorage");
      return;
    }

    try {
      // Llamada al API con el token en el header
      const response = await fetch(`${process.env.REACT_APP_API_URL}/availabilityWeb/0`, {
        method: "GET", // O el método que corresponda
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Enviar el token en el header
        },
      });

      const data = await response.json();
      console.log(data)
      if (data) {
        setBranchAvailability(data.data.availability); // Almacenamos la disponibilidad
      }
    } catch (error) {
      console.error("Error fetching availability data", error);
    }
  };

  return (
    <DateInput
      validate={[required()]}
      source="date"
      label="Fecha de reserva"
      inputVariant="outlined"      
      options={{
        format: "dd/MM/YYY",
        clearable: true,
        disablePast: true,      
      }}
      providerOptions={{ locale: es }}      
      shouldDisableDate={(day) => {
        const date = moment(day);
        const startDate = watch("startDate");
        if (button === "sobreHorario") {
          if (branchAvailability) {
            const dayIndex = date.isoWeekday() - 1; // El día de la semana (0 = Lunes, 6 = Domingo)
            const available = branchAvailability.find(item => item.dayIndex === dayIndex);
            return !available;
          }
          return true;
        }
        if (date.isBefore(startDate, "day")) {
          return true;
        }
        return (
          date < moment().startOf("day") ||
          availability[date.isoWeekday() - 1].length === 0 ||
          blockedDates.some((blockedDate) => date.isSame(blockedDate, "day"))
        );
      }}
      {...props}
    />
  );
};

const _HourInput = ({ availability, source, dateSource, label }) => {
  const { watch, setValue } = useFormContext();
  const date = watch(dateSource);
  const startHour = watch("startHour");
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const [hourPicker, setHourPicker] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { permissions } = usePermissions();
  const profile = JSON.parse(localStorage.getItem("profile"));
  const providerId = permissions !== "admin-sucursal" ? profile._id : profile.employer._id;

  useEffect(() => {
    if (searchParams.get("date")) {
      setValue(dateSource, moment(searchParams.get("date")));
    }
  }, [searchParams.get(dateSource)]);

  useEffect(() => {
    _setHourPicker(moment(date));
    setValue(source, "");
  }, [date]);      

  useEffect(() => {
    if (source === "endHour") {
      _setHourPicker(moment(date));
      setValue(source, "");
    }
  }, [startHour]);

  const [fetchAvailability, { isLoading, error }] = useCreate();

  const _setHourPicker = async (date) => {
    let array = [];
    let today = moment();
    let isSameDay = moment(today).isSame(date, "day");

    await fetchAvailability(
      "dateAvailabilityWithoutBranch/" + providerId,
      {
        data: {
          date: date,
          estimatedTime: 1,
        },
      },
      {
        onSuccess: (response) => {
          response.data.availability.map((e) => {
            const _date = moment(e);

            if ((isSameDay && _date.hours() <= today.hours()) || (startDate === endDate && _date.hours() <= startHour)) {
              return;
            }

            array.push({
              id: _date.hours() + _date.minutes() / 60,
              name: _date.format("HH:mm"),
            });
          });

          setHourPicker(array);
        },
      }
    );
  };

  return <SelectInput validate={[required()]} source={source} label={label} choices={hourPicker} disabled={hourPicker.length === 0} />;
};

export const Create = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const initAvailability = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
  const [availability, setAvailability] = useState(initAvailability);
  const { permissions } = usePermissions();
  const [blockedDates, setBlockedDates] = useState([]);
  const [startHour, setStartHour] = useState(null);
  const profile = JSON.parse(localStorage.getItem("profile"));
  const {
    data: user,
    isLoading,
    error,
  } = useGetOne("availability", {
    id: permissions !== "admin-sucursal" ? profile._id : profile.employer._id,
  });

  useEffect(() => {
    if (user?.availability) {
      const _availability = user?.availability?.reduce((array, e) => {
        return {
          ...array,
          [e.dayIndex]: [...array[e.dayIndex], e],
        };
      }, initAvailability);
      setAvailability(_availability);
    }
  }, [user]);

  const transform = (data) => {
    
    try {
      const provider = permissions !== "admin-sucursal" ? profile : profile.employer;
      const startDate = moment(data.startDate)
      .hours(data.startHour)
      .minutes((data.startHour % 1) * 60)
      .format();
      
      const endDate = data.endDate
      ? moment(data.endDate)
      .hours(data.endHour)
      .minutes((data.endHour % 1) * 60)
      .format()
      : moment(data.startDate)
      .hours(data.startHour)
      .minutes((data.startHour % 1) * 60)
      .format();
      
      const unavailability = {
        ...data,
        startDate,
        endDate,
        provider: {
          ...provider,
          name: provider.first_name,
        },
      };      
      if (data.employee) {
        unavailability.employee = {
          ...data.employee,
          fullName: data.employee.first_name + " " + data.employee.last_name,
          CI: data.employee.CI_NIT,
        };
      }
      return unavailability;
    } catch (error) {
      console.log("error", error);      
    }
  };

  
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSubmit = async (data) => {
    try {
      const transformData = transform(data);
      const response = await dataProvider.create("unavailability", { data: transformData });
      console.log("response: ", response.data?.data);
      const error = response.data?.data?.error;
      if (error) {
        console.log("response: ", response.data.error); 
        throw new Error(error);
      }
      const redirectParam = searchParams.get("redirect") ? searchParams.get("redirect") + "?date=" + data.startDate : null;
      const resource = redirectParam ?? "unavailability";      

      redirect("list", resource); 
    } catch (error) {
      notify(error.message, { type: "error", style: { backgroundColor: "red", color: "white" } });
    }
  };
 
  return (
    <BaseCreate
      {...props}
      // transform={transform}
      // redirect={(resource, id, data, state) => {
      //   const redirectParam = searchParams.get("redirect") ? searchParams.get("redirect") + "?date=" + data.startDate : null;
      //   return redirectParam ?? "unavailability";
      // }}    
    >
      <SimpleForm
        onSubmit={handleSubmit}
        validate={(values) => {
          values.startDate = moment(values.startDate).format();
          values.endDate = moment(values.endDate).format();

          if (moment(values.endDate).isBefore(moment(values.startDate))) {
            return { endDate: { message: "La fecha de finalización no puede ser antes de la fecha de inicio." } };
          }
        }}
      >
        <ReferenceEmployee
          setAvailability={setAvailability}
          initAvailability={initAvailability}
          setBlockedDates={setBlockedDates}
        />
        <_DateInput 
          availability={availability} 
          blockedDates={blockedDates} 
          source="startDate" 
          label="Fecha de Inicio" 
        />
        <_HourInput 
          availability={availability} 
          source="startHour" 
          dateSource="startDate" 
          label="Hora Inicial" 
        />
        <_DateInput 
          availability={availability} 
          blockedDates={blockedDates}
          minValue="startDate"
          source="endDate" 
          label="Fecha Final"
        />
        <_HourInput 
          availability={availability} 
          source="endHour" 
          dateSource="endDate" 
          label="Hora Final" 
        />
        <TextInput source="reason" label="Comentario" />
      </SimpleForm>
    </BaseCreate>
  );
};

const SelectEmployeeInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const profile = JSON.parse(localStorage.getItem("profile"));
  const branchId = profile?.branch?._id;
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <SelectInput
      {...props}
      label="Empleado"
      optionText={(value) => {
        return value.first_name + " " + value.last_name;
      }}
      optionValue="_id"
      // disabled={!date || !hour}
      parse={(value) => {
        return choicesContext?.allChoices.find((e) => e._id === value);
      }}
      format={(value) => {
        return value?._id;
      }}
      source={choicesContext.source}
      choices={
       branchId ? choicesContext.allChoices?.filter((e) => {
          return e.branch?._id === branchId;
        }) : choicesContext.allChoices?.filter((e) => {
          return e;
        })    
      }
      defaultValue={choicesContext?.allChoices.find(
        (e) => e._id === searchParams.get("employee")
      )}
    />
  );
};

const ReferenceEmployee = ({ setAvailability, initAvailability, setBlockedDates }) => {
  const date = useFormContext().watch("date");
  const hour = useFormContext().watch("hour");  
  const isInBranch = useFormContext().watch("isInBranch");
  const branch = useFormContext().watch("branch");
  const [totalEstimatedWorkMinutes, setTotalEstimatedWorkMinutes] = useState(0);
  const [endDate, setEndDate] = useState(moment().format());
  const [bookingDate, setBookingDate] = useState(null);
  const editedEstimatedWorkMinutes = useFormContext().watch(
    "editedEstimatedWorkMinutes"
  );
  const { permissions } = usePermissions();

  const employee = useFormContext().watch("employee");
  const {
    data: user,
  } = useGetOne("availability", {
    id: employee?._id ?? JSON.parse(localStorage.getItem("profile"))._id,
    meta: { branch: isInBranch ? branch?._id : null },
  });
  const getBlockedDates = (unavailablePeriods) => {
    let blockedDates = [];
  
    unavailablePeriods.forEach(({ startDate, endDate }) => {
      let start = moment(startDate);
      let end = moment(endDate);
  
      let startDay = start.clone().startOf("day"); // Día de inicio (00:00)
      let endDay = end.clone().startOf("day"); // Día de fin (00:00)
  
      while (startDay.isSameOrBefore(endDay)) {
        // Control del primer día
        if (startDay.isSame(start, "day")) {
          if (start.hours() <= 8) {
            blockedDates.push(startDay.clone()); // Bloquear completamente
          }
        } 
        // Control del último día
        else if (startDay.isSame(end, "day")) {
          if (end.hours() >= 23) {
            blockedDates.push(startDay.clone()); // Bloquear completamente
          }
        } 
        // Días intermedios siempre bloqueados
        else {
          blockedDates.push(startDay.clone());
        }
  
        startDay.add(1, "day"); // Avanzar un día
      }
    });
  
    return blockedDates;
  };
  useEffect(() => {
    if (user?.unavailability) {
      setBlockedDates(getBlockedDates(user?.unavailability));
    }
    if (user?.availability) {
      const _availability = user?.availability?.reduce((array, e) => {
        return {
          ...array,
          [e.dayIndex]: [...array[e.dayIndex], e],
        };
      }, initAvailability);
      setAvailability(_availability);
    }
  }, [user]);

  useEffect(() => {
    if (date && hour) {
      setBookingDate(
        moment(date)
          .hours(hour)
          .minutes((hour % 1) * 60)
          .format()
      );
    }
  }, [date, hour]);

  useEffect(() => {
    setEndDate(
      moment(bookingDate).add(totalEstimatedWorkMinutes, "minutes").format()
    );
  }, [totalEstimatedWorkMinutes, bookingDate]);

    const profile = JSON.parse(localStorage.getItem("profile"));
    const providerId = permissions === "admin-sucursal" ? profile.employer._id : profile._id;    
    const branchId = profile?.branch?._id;    
    const branchUrl = branchId !== undefined ? `?branchId=${branchId ?? ""}` : "";    
  return (
    <ReferenceInput
      label="Empleado"
      source="employee"
      reference={"employeesAll"}
      filter={{
        providerId,
        branchUrl,
      }}
    >
      <SelectEmployeeInput />
    </ReferenceInput>
  );
};