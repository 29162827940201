import React, { useState, useEffect, useRef, memo } from "react";
import {
  useListContext,
  ListBase,
  useGetList,
  usePermissions,
  required,
  SelectInput,
  useCreate,
  useGetOne,
  SimpleForm,
  SaveButton,
  useUpdate,
  useNotify,
  useDataProvider,
  useRefresh,
  useRedirect,
} from "react-admin";
import { DateInput } from "../components/DatePicker";
import { Scheduler } from "@aldabil/react-scheduler";
import {
  Button,
  ButtonBase,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Dialog,
  DialogTitle,
  Box,
  Divider,
  styled,
  Popper,
} from "@mui/material";
import { es } from "date-fns/esm/locale";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import Firebase from "../Firebase/Firebase";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { AttachMoney, Autorenew, MoneyOff, Computer, Beenhere, Cancel, CheckCircle, DoNotDisturb, HourglassEmpty, MarkEmailRead, PendingActions, Phone, ReportProblem, Tag, Star, Discount} from "@mui/icons-material";
import { STATUS_COLORS } from "./constants";

export const ScheduleList = (props) => {
  const [date, setDate] = useState(new Date().toISOString());
  const [timeUnit, setTimeUnit] = useState("day");
  const [serviceMethod, setServiceMethod] = useState("Ambos");
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [branch, setBranch] = useState(profile?.branch);
  const [searchParams, setSearchParams] = useSearchParams();
  const { permissions } = usePermissions();

  useEffect(() => {
    if (searchParams.get("date")) {
      setDate(new Date(searchParams.get("date")).toISOString());
    }
  }, [searchParams.get("date")]);

  useEffect(() => {
    if (searchParams.get("branch")) {
      setBranch(searchParams.get("branch"));
    }
  }, [searchParams.get("branch")]);

  useEffect(() => { 
    if (searchParams.get("serviceMethod")) {
      setServiceMethod(searchParams.get("serviceMethod"));
    }
  }, [searchParams.get("serviceMethod")]);
  
  return (
    <Firebase>
      <ListBase
        {...props}
        filter={{
          date: date,
          timeUnit: timeUnit,
          serviceMethod: serviceMethod,
          branch: branch,
        }}
      >
        <br />
        <FormControl>
          <InputLabel id="demo-simple-select-label">Método</InputLabel>
          <Select
            style={{ width: 200 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={serviceMethod}
            label="Método"
            onChange={(e) => {
              setServiceMethod(e.target.value);
              if (e.target.value === "A domicilio") {
                setBranch(null);
              }
            }}
          >
            <MenuItem value="Ambos">Ambos</MenuItem>
            <MenuItem value="En sucursal">En sucursal</MenuItem>
            <MenuItem value="A domicilio">A domicilio</MenuItem>
          </Select>
        </FormControl>
        <br />
        { permissions !== "admin-sucursal" && <SelectBranchInput
          serviceMethod={serviceMethod}
          branch={branch}
          setBranch={setBranch}
        /> 
        }
        <Schedule
          date={date}
          serviceMethod={serviceMethod}
          branch={branch}
          setDate={setDate}
          setTimeUnit={setTimeUnit}
        />
      </ListBase>
    </Firebase>
  );
};

const CustomEditor = ({ scheduler, serviceMethod, branch }) => {
  const navigate = useNavigate();
  return (
    <DialogContent>
      <Typography>
        Hora: {scheduler?.state?.start?.value.toLocaleTimeString("es-ES")}
      </Typography>
      <br />
      <Button
        variant="outlined"
        onClick={() =>
          navigate(
            `/booking/create?date=${scheduler?.state?.start?.value.toISOString()}&employee=${
              scheduler.admin_id
            }&serviceMethod=${serviceMethod}&branch=${branch}&redirect=schedule`
          )
        }
        fullWidth
      >
        Crear Reserva
      </Button>
      <br />
      <br />
      <Button
        variant="outlined"
        onClick={() =>
          navigate(
            `/booking/create?date=${scheduler?.state?.start?.value.toISOString()}&employee=${
              scheduler.admin_id
            }&serviceMethod=${serviceMethod}&branch=${branch}&redirect=schedule&type=sobreHorario`
          )
        }
        fullWidth
      >
        Sobre Horario
      </Button>
      <br />
      <br />
      <Button
        variant="outlined"
        onClick={() =>
          navigate(
            `/unavailability/create?date=${scheduler?.state?.start?.value.toISOString()}&employee=${
              scheduler.admin_id
            }&redirect=schedule`
          )
        }
        fullWidth
      >
        Horario No Disponible
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={scheduler.close} fullWidth>
        Cancelar
      </Button>
    </DialogContent>
  );
};

const HourInput = ({ event, branch, isDateBlocked }) => {
  const { watch, setValue } = useFormContext();
  const date = watch("date");
  const hour = watch("hour"); 
  const [hourPicker, setHourPicker] = useState([]);
  const [eventHour, setEventHour] = useState(null);
  const editedEstimatedWorkMinutes = watch("editedEstimatedWorkMinutes");
  const refresh = useRefresh();
  useEffect(() => {
    _setHourPicker(moment(date));
    setValue("hour", "");
  }, [date]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("date")) {
      setValue("date", new Date(searchParams.get("date")));
    }
  }, [searchParams.get("date")]);

  const [fetchAvailability, { isLoading, error }] = useCreate();

  const _setHourPicker = async (date) => {
    let array = [];
    let today = moment();
    let isSameDay = moment(today).isSame(date, "day");
    await fetchAvailability(
      "dateAvailability/" + JSON.parse(localStorage.getItem("profile"))._id,
      {
        data: {
          date: date,
          estimatedTime: editedEstimatedWorkMinutes,
          isInBranch: event.event.isInBranch,
          branch: event.event.branch._id,
          employee: event.event.admin_id,
          serviceCart: event.event.services?.map((e) => e.service._id) ?? [],
        },
      },
      {
        onSuccess: (response) => {
          response.data.availability.map((e) => {
            const _date = moment(e);
            if (isSameDay && _date.hours() <= today.hours()) {
              // return;
            }
            array.push({
              id: _date.hours() + _date.minutes() / 60,
              name: _date.format("HH:mm"),
            });
          });
          setHourPicker(array);
        },
      }
    );
  };
  // Guardar la hora del evento en estado separado
  useEffect(() => {
    if (event.event?.start) {
      const eventHourMoment = moment(event.event.start);
      const eventHourId = eventHourMoment.hours() + eventHourMoment.minutes() / 60;
      const eventHourName = eventHourMoment.format("HH:mm");

      setEventHour({ id: eventHourId, name: eventHourName });
    }
  }, [event.event?.start]);

  // Agregar la hora del evento una vez que hourPicker ha sido actualizado
  useEffect(() => {
    if (eventHour && !hourPicker.some(e => e.id === eventHour.id)) {
      setHourPicker(prev =>
        [...prev, eventHour].sort((a, b) => a.id - b.id)
      );    
    }

    if (eventHour) {
      setValue("hour", eventHour.id); // Establecer la hora en el formulario
    }
  }, [hourPicker, eventHour]);
  return (
    <SelectInput
      source="hour"
      label="Hora"
      choices={hourPicker}
      fullWidth
      helperText={isDateBlocked || hourPicker.length === 0 ? "Sin horarios disponibles en esa fecha." : ""}
      sx={{
        "& .MuiPaper-root": {
          maxHeight: "200px",
          overflowY: "auto",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 200, // Limita la altura del menú
            overflowY: "auto", // Activa el scroll
          },
        },
      }}
    />
  );
};

const AvailableDateInput = ({ event, availability, blockedDates = [], busyDates, setIsDateBlocked,...props }) => {
  return (
    <DateInput
      validate={[required()]}
      source="date"
      label="Fecha de reserva"
      inputVariant="outlined"
      event = {event}
      options={{
        format: "dd/MM/yyyy",
        clearable: true,
        disablePast: true,
        defaultValue: `${event?.start ? new Date(event.start).toISOString() : null}`
      }}
      defaultValue={event?.start ? new Date(event.start).toISOString() : null}
      providerOptions={{ locale: es }}
      shouldDisableDate={(day) => {
        const date = moment(day);
        const dayIndex = date.isoWeekday() - 1;
        const employeeAvailability = availability[dayIndex];
        if (employeeAvailability.length === 0) return true; 
        const busyPeriods = busyDates
          .filter(busy => moment(busy.start).isSame(date, "day"))
          .map(({ start, end }) => ({
            start: moment(start).hours() * 60 + moment(start).minutes(),
            end: moment(end).hours() * 60 + moment(end).minutes(),
          }));
        let availableMinutes = new Set();
        employeeAvailability.forEach(({ startHour, startMinute, endHour, endMinute }) => {
          for (let i = startHour * 60 + startMinute; i < endHour * 60 + endMinute; i++) {
            availableMinutes.add(i);
          }
        });

        busyPeriods.forEach(({ start, end }) => {
          for (let i = start; i < end; i++) {
            availableMinutes.delete(i);
          }
        });
        const allIntervalsBusy = availableMinutes.size === 0;
        const isBlocked = date < moment().startOf("day") || allIntervalsBusy || blockedDates.some(blockedDate => date.isSame(blockedDate, "day"))||availability[date.isoWeekday() - 1].length === 0;
        return isBlocked;
      }}
      setIsDateBlocked={setIsDateBlocked}
      {...props}
    />
  );
};

const DialogDate = ({ scheduler, serviceMethod, branch, handleClose, setDate }) => {
  const [update, { loading }] = useUpdate();
  const notify = useNotify(); 
  const initAvailability = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
  const [availability, setAvailability] = useState(initAvailability);
  const [blockedDates, setBlockedDates] = useState([]); 
  const {
    data: user,
  } = useGetOne("availability", {
    id: scheduler?.event.admin_id ?? JSON.parse(localStorage.getItem("profile"))._id,
    meta: { branch: branch ? branch?._id : null },
  });
  const [isDateBlocked, setIsDateBlocked] = useState(false);
  const [busyDates, setBusyDates] = useState([]); 
  const getBlockedDates = (unavailablePeriods) => {
      let blockedDates = [];
    
      unavailablePeriods.forEach(({ startDate, endDate }) => {
        let start = moment(startDate);
        let end = moment(endDate);
    
        let startDay = start.clone().startOf("day"); 
        let endDay = end.clone().startOf("day"); 
    
        while (startDay.isSameOrBefore(endDay)) {
          if (startDay.isSame(start, "day")) {
            if (start.hours() <= 8) {
              blockedDates.push(startDay.clone());
            }
          } 
          else if (startDay.isSame(end, "day")) {
            if (end.hours() >= 23) {
              blockedDates.push(startDay.clone()); // Bloquear completamente
            }
          } 
          // Días intermedios siempre bloqueados
          else {
            blockedDates.push(startDay.clone());
          }
    
          startDay.add(1, "day"); // Avanzar un día
        }
      });
    
      return blockedDates;
  };
  useEffect(() => {
    if (user?.busy) {
      // setBusyDates(getFullyBusyDays(user?.busy));
      setBusyDates(user?.busy);
    }
    if (user?.unavailability) {
      setBlockedDates(getBlockedDates(user?.unavailability));
    }
    if (user?.availability) {
      const _availability = user?.availability?.reduce((array, e) => {
        return {
          ...array,
          [e.dayIndex]: [...array[e.dayIndex], e],
        };
      }, initAvailability);
      setAvailability(_availability);
    }
  }, [user]);
  const redirect = useRedirect();
  const handleUpdate = (data) => {
    const { date, hour, editedEstimatedWorkMinutes } = data;
    update(
      "booking",
      {
        id: scheduler.event.event_id,
        data: { bookingDate: moment(date)
                .hours(hour)
                .minutes((hour % 1) * 60)
                .format(), 
              totalEstimatedWorkMinutes: editedEstimatedWorkMinutes 
        },
        meta: { customAction: "updateDate" }, 
      },
      {
        onSuccess: (updatedData) => {
          notify("Fecha de la reserva actualizada con éxito", "success");
          handleClose(); 
          // setDate(moment(date).toISOString());
          redirect(`/schedule?date=${updatedData.bookingDate}`);
        },
        onFailure: (error) => notify(`Error al actualizar: ${error.message}`, "warning"),
      }
    );
  };
  return (
    <DialogContent>
      <Typography mb={2}>
        Seleccione la nueva fecha y hora:
      </Typography>
      <SimpleForm sx={{ padding: 0 }} toolbar={false} onSubmit={handleUpdate}>
        <AvailableDateInput event={scheduler?.event} availability={availability} blockedDates={blockedDates} setIsDateBlocked={setIsDateBlocked} busyDates={busyDates}/>
        <br />
        <SelectInput
          // validate={[required()]}
          defaultValue={scheduler?.event?.totalEstimatedWorkMinutes}
          source="editedEstimatedWorkMinutes"
          label="Tiempo de servicio estimado"
          fullWidth
          choices={[
            { id: 0, name: "0 Minutos" },
            { id: 30, name: "30 Minutos" },
            { id: 60, name: "1 Hora" },
            { id: 90, name: "1 Hora 30 Minutos" },
            { id: 120, name: "2 Horas" },
            { id: 150, name: "2 Horas 30 Minutos" },
            { id: 180, name: "3 Horas" },
            { id: 210, name: "3 Horas 30 Minutos" },
            { id: 240, name: "4 Horas" },
            { id: 270, name: "4 Horas 30 Minutos" },
            { id: 300, name: "5 Horas" },
            { id: 330, name: "5 Horas 30 Minutos" },
            { id: 360, name: "6 Horas" },
            { id: 390, name: "6 Horas 30 Minutos" },
            { id: 420, name: "7 Horas" },
            { id: 450, name: "7 Horas 30 Minutos" },
            { id: 480, name: "8 Horas" },
          ]}
        />
        <HourInput event={scheduler} branch={branch} isDateBlocked={isDateBlocked} />
        <SaveButton fullWidth disabled={loading} />
      </SimpleForm>
      <Button variant="contained" color="secondary" onClick={handleClose} fullWidth>
        Cancelar
      </Button>
    </DialogContent>
  );
};
const status = (status) => {
  if (status === "Pendiente de cotizacion") {
    return [
        { id: "Pendiente de cotizacion", name: "Pendiente de cotización" },
        { id: "Confirmado", name: "Enviar cotización y confirmar" },
        // { id: "Pendiente", name: "Pendiente" },
        // { id: "Confirmado", name: "Confirmado" },
        // { id: "En proceso", name: "En proceso" },
        // { id: "Finalizado", name: "Finalizado" },
        { id: "Cancelada por el proveedor", name: "Cancelado" },
    ];
  }
  if (status === "Cotizacion enviada") {
    return [
      { id: "Cotizacion enviada", name: "Cotización enviada" },
    ];
  }
  if (status === "Pendiente") {
    return [
      { id: "Pendiente", name: "Pendiente" },
      { id: "Confirmado", name: "Confirmar" },
      { id: "Cancelada por el proveedor", name: "Cancelar" },
    ];
  }
  if (status === "Confirmado") {
    return [
      { id: "Confirmado", name: "Confirmado" },
      { id: "En proceso", name: "En proceso" },
      { id: "Finalizado", name: "Finalizar" },
      { id: "Cancelada por el proveedor", name: "Cancelar" },
    ];
  }
  if (status === "En proceso") {
    return [
      { id: "En proceso", name: "En proceso" },
      { id: "Finalizado", name: "Finalizar" },
      { id: "Cancelada por el proveedor", name: "Cancelar" },
    ];
  }
  if (status === "Finalizado") {
    return [
      { id: "Finalizado", name: "Finalizado" },
    ];
  }
  if (status === "Cancelada por el proveedor") {
    return [
      { id: "Cancelada por el proveedor", name: "Cancelado por el proveedor" },
    ];
  }
  if (status === "Cancelada por el cliente") {
    return [
      { id: "Cancelada por el cliente", name: "Cancelado por el cliente" },
    ];
  }
};
const BookingStatus = memo(({ event }) => {
  const [ loading, setLoading ] = useState(false);
  const [choices, setChoices] = useState(status(event.status));
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();  
  const handleChange = async (newStatus) => {
    setLoading(true);
    try {
      await dataProvider.update("booking", {
        id: event.event_id,
        data: { status: newStatus, previousStatus: event.status },
        meta: { customAction: "updateStatus" },
      });
      
      setChoices(status(newStatus));
      refresh();
      notify("Actualizado correctamente", { type: "success" });
    } catch (error) {
      console.error("Error al actualizar estado de pago:", error);
      notify("Error al actualizar", { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <SimpleForm toolbar={false} sx={{ padding: 0,"&:last-child": { paddingBottom: 0 }}}>
      <SelectInput
        source="status"
        label="Estado de Reserva"
        choices={choices}
        disabled={loading}
        defaultValue={event.status}
        // value={event.status}
        onChange={(e) => {
          console.log("e.target.value", e.target.value);
          return handleChange(e.target.value);
        }}
        style={{ margin: 0, padding: 0 }}
      />
    </SimpleForm>
  );
});
const Schedule = ({ date, setDate, setTimeUnit, serviceMethod }) => {
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [branch, setBranch] = useState(profile?.branch);
  const { data } = useListContext();
  const calendarRef = useRef(null);
  useEffect(() => {
    if (Array.isArray(data)) {
      const filteredEvents = data[0].events.filter(event => event.status !== "Cancelada por el proveedor");
      calendarRef.current.scheduler.handleState(data[0].resources, "resources");
      calendarRef.current.scheduler.handleState(
        filteredEvents.map((e) => ({
          ...e,
          start: new Date(e.start),
          end: new Date(e.end),
        })),
        // data[0].events.map((e) => {
        //   return {
        //     ...e,
        //     start: new Date(e.start),
        //     end: new Date(e.end),
        //   };
        // }),
        "events"
      );
    }
  }, [data, date]);

  const [searchParams, setSearchParams] = useSearchParams();
  let initialDate = new Date();
  if (searchParams.get("date")) {
    initialDate = new Date(searchParams.get("date"));
  }
  return (
    <Scheduler
      key={date}
      ref={calendarRef}
      view="day"
      events={[]}
      resources={[]}
      resourceFields={{
        idField: "admin_id",
        textField: "fullName",
        subTextField: "phone",
        avatarField: "fullName",
        colorField: "color",
      }}
      day={{
        startHour: 8,
        endHour: 22,
        step: 30,
        navigation: true,
        cellRenderer: (props) => {
          if (!props || !props.start || !props.admin_id) {
            return  <div
              onClick={props.onClick}
              style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F0F8FF";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#FFFFFF";
              }}
            />;
          }
          const cellStart = moment(props.start);
          const cellHour = cellStart.hour();
          const cellDayIndex = cellStart.isoWeekday() - 1;
        
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { data } = useListContext();
          if (!Array.isArray(data) || !data[0]?.resources) {
            return <div style={{ height: "100%", backgroundColor: "#FFFFFF" }} />;
          }
          const employee = data[0].resources.find(
            (resource) => resource.admin_id === props.admin_id
          );
          // console.log(employee)
          if (employee === undefined) {
            return <div style={{ height: "100%", backgroundColor: "#FFFFFF" }} />;
          }
          if (employee.admin_id === 0) {
            console.log('employee not found')
          }

          if (!employee) {
            return <div style={{ height: "100%", backgroundColor: "#FFFFFF" }} />;
          }
        
          let isAvailable = false;
          if (!Array.isArray(employee.availability) || employee.availability.length === 0) {
            // isAvailable = true;
            isAvailable = cellDayIndex >= 0 && cellDayIndex <= 5;   // de lunes a sabado
          } else {
            isAvailable = employee.availability.some((slot) => {
              const slotStartHour = slot.startHour;
              const slotEndHour = slot.endHour;
              const slotStartMinute = slot.startMinute;
              const slotEndMinute = slot.endMinute;
              const slotDayIndex = slot.dayIndex; // Día de la semana en índice
            
              if (cellDayIndex !== slotDayIndex) {
                return false;
              }
            
              const cellMinutes = cellStart.hour() * 60 + cellStart.minute();
              const slotStartMinutes = slotStartHour * 60 + slotStartMinute;
              const slotEndMinutes = slotEndHour * 60 + slotEndMinute;
            
              return cellMinutes >= slotStartMinutes && cellMinutes < slotEndMinutes;
            });
          }
        
          return (
            <div
              onClick={props.onClick}
              style={{
                backgroundColor: isAvailable ? "#FFFFFF" : "#D3D3D3",
                height: "100%",
                border: isAvailable ? "" : "1px solid #A9A9A9", // 🔹 Azul si disponible, gris si no
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease", // 🔹 Transición suave
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#F0F8FF" : "#B0B0B0"; // 🔹 Azul claro si disponible, gris más oscuro si no
                e.target.style.border = isAvailable ? "" : "1px solid #808080"; // 🔹 Borde más fuerte
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#FFFFFF" : "#D3D3D3"; // 🔹 Volver al color original
                e.target.style.border = isAvailable ? "" : "1px solid #A9A9A9"; // 🔹 Volver al borde original
              }}
            />
          );
        },
      }}
      week={{
        startHour: 8,
        endHour: 22,
        step: 30,
        navigation: true,
        cellRenderer: (props) => {
          if (!props || !props.start || !props.admin_id) {
            return  <div
              onClick={props.onClick}
              style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F0F8FF";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#FFFFFF";
              }}
            />;;
          }
      
          const cellStart = moment(props.start);
          const cellHour = cellStart.hour();
          const cellDayIndex = cellStart.isoWeekday() - 1; // 🔹 Lunes = 0, Martes = 1, etc.
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { data } = useListContext();
      
          if (!Array.isArray(data) || !data[0]?.resources) {
            return <div style={{ height: "100%", backgroundColor: "#D3D3D3" }} />;
          }
      
          const employee = data[0].resources.find(
            (resource) => resource.admin_id === props.admin_id
          );
      
          if (!employee) {
            return <div style={{ height: "100%", backgroundColor: "#D3D3D3" }} />;
          }
      
          let isAvailable = false;
          if (props.admin_id === 0) {
            isAvailable = cellDayIndex >= 0 && cellDayIndex <= 5;   // de lunes a sabado
            // isAvailable = true;
          } else if (!Array.isArray(employee.availability) || employee.availability.length === 0) {
            isAvailable = cellDayIndex >= 0 && cellDayIndex <= 5;   // de lunes a sabado
            // isAvailable = true; 
          } else {
            isAvailable = employee.availability.some((slot) => {
              const slotStartHour = slot.startHour;
              const slotEndHour = slot.endHour;
              const slotStartMinute = slot.startMinute;
              const slotEndMinute = slot.endMinute;
              const slotDayIndex = slot.dayIndex; // Día de la semana en índice
            
              if (cellDayIndex !== slotDayIndex) {
                return false;
              }
            
              const cellMinutes = cellStart.hour() * 60 + cellStart.minute();
              const slotStartMinutes = slotStartHour * 60 + slotStartMinute;
              const slotEndMinutes = slotEndHour * 60 + slotEndMinute;
            
              return cellMinutes >= slotStartMinutes && cellMinutes < slotEndMinutes;
            });
          }
          return (
            <div
              onClick={props.onClick}
              style={{
                backgroundColor: isAvailable ? "#FFFFFF" : "#D3D3D3",
                height: "100%",
                border: isAvailable ? "" : "1px solid #A9A9A9", // 🔹 Azul si disponible, gris si no
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease", // 🔹 Transición suave
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#F0F8FF" : "#B0B0B0"; // 🔹 Azul claro si disponible, gris más oscuro si no
                e.target.style.border = isAvailable ? "" : "1px solid #808080"; // 🔹 Borde más fuerte
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#FFFFFF" : "#D3D3D3"; // 🔹 Volver al color original
                e.target.style.border = isAvailable ? "" : "1px solid #A9A9A9"; // 🔹 Volver al borde original
              }}
            />
          );
        },
      }}
      month={{
        navigation: true,
        cellRenderer: (props) => {
          if (!props || !props.day || !props.admin_id) {
            return  <div
              onClick={props.onClick}
              style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F0F8FF";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#FFFFFF";
              }}
            />;;
          }
          const cellDayIndex = moment(props.end).isoWeekday() - 1; 
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { data } = useListContext();
      
          if (!Array.isArray(data) || !data[0]?.resources) {
            return <div style={{ height: "100%", backgroundColor: "#D3D3D3" }} />;
          }
      
          const employee = data[0].resources.find(
            (resource) => resource.admin_id === props.admin_id
          );
      
          if (!employee) {
            return <div style={{ height: "100%", backgroundColor: "#D3D3D3" }} />;
          }
      
          let isAvailable = false;
          if (!Array.isArray(employee.availability) || employee.availability.length === 0) {
            isAvailable = cellDayIndex >= 0 && cellDayIndex <= 5;   // de lunes a sabado
            // isAvailable = true; 
          } else {
            isAvailable = employee.availability.some((slot) => {
              const slotDayIndex = slot.dayIndex; 
              return cellDayIndex === slotDayIndex;
            });
          }
      
          return (
            <div
              onClick={props.onClick}
              style={{
                backgroundColor: isAvailable ? "#FFFFFF" : "#D3D3D3",
                height: "100%",
                border: isAvailable ? "" : "1px solid #A9A9A9", 
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease", 
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#F0F8FF" : "#B0B0B0"; 
                e.target.style.border = isAvailable ? "" : "1px solid #808080";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#FFFFFF" : "#D3D3D3"; 
                e.target.style.border = isAvailable ? "" : "1px solid #A9A9A9"; 
              }}
            />
          );
        },
      }}
      
      translations={{
        navigation: {
          month: "Mes",
          week: "Semana",
          day: "Día",
          today: "Hoy",
        },
        form: {
          addTitle: "Crear Reserva",
          editTitle: "Ver Reserva",
          confirm: "Confirmar",
          delete: "Borrar",
          cancel: "Cancelar",
        },
        event: {
          title: "Título",
          start: "Inicio",
          end: "Final",
          allDay: "Todo el día",
        },
        moreEvents: "Más...",
        loading: "Cargando...",
      }}
      locale={es}
      selectedDate={new Date(date)}
      onSelectedDateChange={(d) => setDate(d.toISOString())}
      onViewChange={(v) => setTimeUnit(v)}
      deletable={false}
      draggable={false}
      editable={false}
      customEditor={(scheduler) => (
        <CustomEditor
          scheduler={scheduler}
          branch={branch}
          serviceMethod={serviceMethod}
        />
      )}
      eventRenderer={({ event, onClick}) => {
        return (
          <ButtonBase onClick={onClick}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: event.type === "unavailability" ? "#D3D3D3" : STATUS_COLORS[event.status],
                height: "100%",
              }}
            >
              { event?.paymentInfo?.status === "Pagado" &&
                <div
                  style={{
                    width: "1rem",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#4CAF50",
                    borderRight: "1px solid #fff",
                  }}
                >
                  <AttachMoney style={{ color: "#FFF", fontSize: "1rem" }} />
                </div>
              }
              <div
                style={{
                  flex: 1,
                  padding: "4px",
                  display: "flex",
                  flexDirection: "column",
                }}  
              >
                <Typography variant="subtitle2" style={{ fontSize: "x-small" }}>
                  {event.title}
                </Typography>
                {event.type === "booking" &&(
                    <Typography style={{ fontSize: "x-small" }}>
                      {format( event.start, `HH:mm`, {
                        locale: es,
                      })}{" "}
                      -
                      {format(event.end, `HH:mm`, {
                        locale: es,
                      })}
                    </Typography>
                )}
                {event.type === "booking" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography variant="subtitle2" style={{ 
                      width: "auto",
                      fontSize: "x-small",
                    }}>
                      {event.status}
                    </Typography>
                    { event?.feedbackInfo && event?.feedbackInfo?.rating && 
                      <div 
                        style={{
                          width: "auto",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          textAlign: "center",
                          paddingBottom: "3px"
                        }}
                      >
                        <Typography style={{ fontWeight: "500", fontSize: "x-small", width: "0.75rem", height: "0.75rem"}}>
                          {event.feedbackInfo.rating}
                        </Typography>
                        <Star style={{ color: "#FFD700", width: "0.75rem", height: "0.75rem"}}/>
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
          </ButtonBase>
        )
      }}
      viewerExtraComponent={(fields, event) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [open, setOpen] = useState(false); // Estado para el modal
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [update, { loading }] = useUpdate();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const notify = useNotify();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { refetch } = useListContext();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate();
        // console.log(event)
        const WhatsAppField = ({ phone, countryCode }) => {
          if (!phone) return null;
          const code = countryCode ? countryCode : "";
          return (
              <a href={`https://wa.me/${code}${phone}`} target="_blank" rel="noreferrer" style={{ textDecoration: "underline", color: "#FF770D", marginLeft: 8 }}>
                  {phone}
              </a>
          );
      };
        const InfoItem = ({ icon, label, value, countryCode }) => {
            return (
                <Box display="flex" marginTop={label==="Estado"? 2: 1}>
                    <Box display="flex">
                        {icon}
                        <Typography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                            {label}:
                        </Typography>
                    </Box>
                    {label === "Teléfono"?
                        <WhatsAppField phone={value} countryCode={countryCode}/>
                    :
                        <Typography variant="body2" marginLeft={1}>{value}</Typography>
                    }
                </Box>
            );
        }
        
        const cancel = () =>
        update(
          "booking",
          {
            id: event.event_id,
            data: {},
            meta: { customAction: "toCancelledByProvider" },
          },
          {
            onSuccess: (data) => {
              const redirectParam = searchParams.get("redirect")
                ? searchParams.get("redirect") + "?date=" + data.bookingDate
                : null;
              notify("Reserva cancelada con éxito");
              refetch();
            },
            onFailure: (error) =>
              notify(`Error al cancelar reserva: ${error}`, "warning"),
          }
        );
        const handleOpen = () => setOpen(true); 
        const handleClose = () => setOpen(false);
        const getStatusIcon = (status) => {
          const icons = {
            "Pendiente de cotización": <HourglassEmpty fontSize="small"/>,
            "Cotización enviada": <MarkEmailRead fontSize="small"/>,
            "Pendiente": <PendingActions fontSize="small"/>,
            "Confirmado": <CheckCircle fontSize="small"/>,
            "En proceso": <Autorenew fontSize="small"/>,
            "Finalizado": <Beenhere fontSize="small"/>,
            "Cancelado": <Cancel fontSize="small"/>,
            "Cancelado por el cliente": <DoNotDisturb fontSize="small"/>,
            "Cancelado por el proveedor": <ReportProblem fontSize="small"/>,
          };
        
          return icons[status] || <Cancel color="disabled" />; // Icono por defecto si no hay coincidencia
        };
        return (
          <div>
            <Divider sx={{ my: 1, bgcolor: "rgba(0, 0, 0, 0.1)" }} />
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
              gap: "10px",
            }}>
              <InfoItem icon={<Tag fontSize="small" />} label="ID" value={event?.event_id || "No disponible"}/>
              { event?.serviceInfo && event?.currentSession && event.serviceInfo?.package?.quantity && 
                <Typography fontSize="small" fontWeight={700} marginTop={1}>
                  {`|  Sesión ${event?.currentSession} de ${event?.serviceInfo?.package?.quantity}`}
                </Typography>
              }
            </div>
            <InfoItem icon={<Phone fontSize="small" />} label="Teléfono" value={event?.phone || "No disponible"} countryCode={event?.countryCode}/>
            <InfoItem icon={<Computer fontSize="small" />} label="Método de reserva" value={event?.createdFrom || "-"}/>
            <InfoItem icon={<Discount fontSize="small" />} label="Campaña de descuento" value={event?.paymentInfo?.coupon?.name || "-"}/>
            { event?.status !== "No disponible" &&
              <InfoItem
                  icon={getStatusIcon(event.status)}
                  label="Estado"
                  value={
                    <BookingStatus event={event}/>
                  }
              />
            }            
            <Box
              sx={{
                display: "flex",
                gap: 0.5, // 🔹 Menos espacio entre los botones
                mt: 0.5,  // 🔹 Menos margen superior
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleOpen}
                disabled={event.remainingDateChanges <= 0 || event.type === "unavailability"}
                sx={{
                  flex: 1,
                  minWidth: "90px", // 🔹 Más pequeño
                  maxWidth: "110px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  padding: "4px", 
                  fontSize: "12px", 
                  color: "white"
                }}
              >
                Modificar <br /> Fecha
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate(`/${event.type}/${event.event_id}/show?redirect=schedule`)}
                sx={{
                  flex: 1,
                  minWidth: "90px",
                  maxWidth: "110px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  padding: "4px",
                  fontSize: "12px",
                }}
              >
                Más <br /> Información
              </Button>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={cancel}
                disabled={event.type === "unavailability"}
                sx={{
                  flex: 1,
                  minWidth: "90px",
                  maxWidth: "110px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  padding: "4px",
                  fontSize: "12px",
                }}
              >
                Cancelar <br /> Reserva
              </Button>
            </Box>


            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Modificar Fecha de Reserva</DialogTitle>
              <DialogDate scheduler={{ event }} branch={branch} handleClose={handleClose} setDate={setDate}/>
            </Dialog>
          </div>
        );
      }}
    />
  );
};

const SelectBranchInput = ({ serviceMethod, branch, setBranch }) => {
  const { data, loading, error } = useGetList("enterprise/branches", {
    filter: {
      status: true,
    },
  });

  return serviceMethod !== "A domicilio" ? (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Sucursal</InputLabel>
      <Select
        style={{ width: 200 }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={branch}
        label="Método"
        onChange={(e) => setBranch(e.target.value)}
      >
        {data?.map((e) => {
          return (
            <MenuItem key={e._id} value={e._id}>
              {e.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : (
    <React.Fragment />
  );
};
