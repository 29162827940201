export const STATUS_COLORS = {
   "Pendiente de cotizacion": "#FFE082",
   "Cotizacion enviada": "#FFB74D",
   "Pendiente": "#FFF59D",
   "Confirmado": "#80CBC4",
   "En proceso": "#64B5F6",
   "Finalizado": "#CE93D8", 
   "Cancelada por el proveedor": "#EF9A9A",
   "Cancelada por el cliente": "#FFABAB",
   "Caducado": "#B0BEC5"
};