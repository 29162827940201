import React from "react";
import {
  Create,
  required,
  ImageField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ImageInput,
} from "react-admin";

function formatLogo(value) {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
}

export const SOSList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nombre" />
      <ImageField source="logo" title="logo" />
      <TextField source="phone" label="Teléfono" />
      <BooleanField source="state" label="Estado" />
    </Datagrid>
  </List>
);

export const SOSEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ImageInput
        format={formatLogo}
        validate={[required()]}
        source="logo"
        label="Logo"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput validate={[required()]} source="name" label="Nombre" />
      <TextInput validate={[required()]} source="phone" label="Teléfono" />
      <BooleanInput validate={[required()]} source="state" label="Estado" />
    </SimpleForm>
  </Edit>
);

export const SOSCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ImageInput
        validate={[required()]}
        source="logo"
        label="Logo"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="name" label="Nombre" validate={[required()]} />
      <TextInput source="phone" label="Teléfono" validate={[required()]} />
    </SimpleForm>
  </Create>
);
