import React from "react";
import {
  Admin,
  Resource,
  CustomRoutes,
} from "react-admin";
import dataProvider from "./components/dataProvider";
import {
  CategoryList,
  CategoryEdit,
  CategoryCreate,
} from "./components/category";
import { UserList, UserEdit } from "./components/userList";
import AuthProvider from "./authProvider";
import Dashboard from "./Dashboard";
import polyglotI18nProvider from "ra-i18n-polyglot";
import spanishMessages from "@blackbox-vision/ra-language-spanish";
import profile from "./components/profile";
import { Route } from "react-router-dom";
import MyLayout from "./MyLayout";
import {
  EmployeeList,
  EmployeeCreate,
  EmployeeEdit,
} from "./components/employee";
import { ProductList, ProductCreate, ProductEdit } from "./components/product";
import {
  NotificationList,
  NotificationCreate,
} from "./components/notification";
import { BalanceDeliveryList } from "./components/deliveryBalance";
import { CommissionList, CommissionCreate } from "./components/commission";
import Login from "./Login";
import Register from "./Register";
import { createTheme } from "@mui/material/styles";
import { AvailabilityEdit } from "./components/availability";
import {
  DeliverySettingList,
  DeliverySettingCreate,
  DeliverySettingEdit,
} from "./components/deliverySetting";
import ForgotPasswordPage from "./ForgotPassword";
import ResetPasswordPage from "./ResetPassword";

import { DeliveryList, DeliveryEdit } from "./components/delivery";
import { SOSCreate, SOSEdit, SOSList } from "./components/sos";
import { SaleList, SaleShow } from "./components/sale";
import { ServiceCreate, ServiceEdit, ServiceList } from "./Service";
import { BookingList, BookingShow, BookingCreate } from "./Booking";
import { BalanceList } from "./Booking/Balance";
import { BalanceList as SaleBalanceList } from "./Sale/Balance";
import {
  AdminBookingBalance,
  AdminBookingEdit,
  AdminBookingList,
} from "./Admin/Booking";
import {
  EnterpriseCustomerCreate,
  EnterpriseCustomerEdit,
  EnterpriseCustomerList,
} from "./Enterprise/Customer";
import { SaleCreate } from "./Sale";
import { ScheduleList } from "./Schedule/Schedule";
import {
  UnavailabilityCreate,
  UnavailabilityEdit,
  UnavailabilityList,
} from "./Unavailability";
import { Tutorial } from "./Tutorial/Tutorial";
import { BranchCreate, BranchEdit, BranchList, BranchShow } from "./Enterprise/Branch";
import { CouponCreate, CouponEdit, CouponList } from "./Coupon";
import GroupsIcon from '@mui/icons-material/Groups';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import GroupIcon from "@mui/icons-material/Group";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import BusinessIcon from "@mui/icons-material/Business";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AirtablePage from "./AirtablePage";
import { FilterProvider } from "./Booking/FilterContext";
import CustomerServicesPage from "./Enterprise/Customer/components/ServicePage";

const i18nProvider = polyglotI18nProvider(() => spanishMessages, "es");

const muiTheme = createTheme({
  palette: {
    primary: { main: "#FF770D", menuIcon: "#757575" },
    secondary: { main: "#252526" },
  },
});

const App = () => {
  window.hashTalkSmart = "9e0c1de1-6e40-4008-95be-d9f4afb7d08a";

  let scriptUrlTalkSmart = "https://toolhero.desarrollamelo.com/iframe/js/script.tuchatinteligente.min.js";
  let randomVersionTalkSmart = Math.random().toString(36).substring(2); // Generate a random version string

  let newScriptUrlTalkSmart = scriptUrlTalkSmart + "?v=" + randomVersionTalkSmart;

  let scriptElementTalkSmart = document.createElement("script");
  scriptElementTalkSmart.src = newScriptUrlTalkSmart;
  scriptElementTalkSmart.setAttribute("hash", "9e0c1de1-6e40-4008-95be-d9f4afb7d08a");
  document.head.appendChild(scriptElementTalkSmart);
  
  return (
    <Admin
      i18nProvider={i18nProvider}
      authProvider={AuthProvider}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      loginPage={Login}
      theme={muiTheme}
      layout={MyLayout}
    >
      <CustomRoutes noLayout>
        <Route path="/register" element={<Register />} />,
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/forgotPassword" element={<ForgotPasswordPage />} />,
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/resetPassword" element={<ResetPasswordPage />} />,
      </CustomRoutes>
      <CustomRoutes>
        <Route key="my-profile" path="/my-profile" element={<profile.edit />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route
          key="update-password"
          path="/update-password"
          element={<profile.password />}
        />
      </CustomRoutes>
      {(permissions) => {
        return [
        permissions === "admin" && (
          <Resource
            name="categories"
            list={CategoryList}
            edit={CategoryEdit}
            create={CategoryCreate}
            label="Categorías"
            options={{ label: "Categorías" }}
          />
        ),
        permissions === "admin" && (
          <Resource
            name="providers"
            list={UserList}
            edit={UserEdit}
            options={{ label: "Proveedores" }}
          />
        ),
        permissions === "admin" && (
          <Resource
            name="usersEnable"
            list={UserList}
            edit={UserEdit}
            options={{ label: "Habilitar Proveedores" }}
          />
        ),
        (permissions === "admin" || permissions === "marketing") && (
          <Resource
            name="notification"
            list={NotificationList}
            create={NotificationCreate}
            options={{ label: "Notificaciones" }}
          />
        ),
        permissions === "admin" && (
          <Resource
            name="admin/booking"
            list={AdminBookingList}
            edit={AdminBookingEdit}
            options={{ label: "Reservas" }}
            
          />
        ),
        permissions === "admin" && (
          <Resource
            name="adminBookingBalance"
            list={AdminBookingBalance}
            edit={AdminBookingEdit}
            options={{ label: "Balance de Servicios" }}
          />
        ),
        permissions === "admin" && (
          <Resource
            name="commission"
            list={CommissionList}
            create={CommissionCreate}
            options={{ label: "Comisiones" }}
          />
        ),
        permissions === "admin" && (
          <Resource
            name="deliverysetting"
            list={DeliverySettingList}
            edit={DeliverySettingEdit}
            create={DeliverySettingCreate}
            options={{ label: "Ajustes de Envíos" }}
          />
        ),
        permissions === "admin" && (
          <Resource
            name="delivery"
            list={DeliveryList}
            edit={DeliveryEdit}
            options={{ label: "Envíos" }}
          />
        ),
        permissions === "admin" && (
          <Resource
            name="deliveryBalance"
            list={BalanceDeliveryList}
            options={{ label: "Balance de Envíos" }}
          />
        ),
        permissions === "admin" && (
          <Resource
            name="sos"
            list={SOSList}
            edit={SOSEdit}
            create={SOSCreate}
            label="SOS"
            options={{ label: "SOS" }}
          />
        ),

        (permissions === "proveedor-empresa" || permissions === "admin-sucursal")&& (
          <Resource
            name="service"
            list={ServiceList}
            edit={ServiceEdit}
            create={permissions === "proveedor-empresa" ? ServiceCreate : null}
            options={{ label: "Servicios", parent: "settings" }}
            icon={LocalMallIcon}
          />
        ),

        permissions === "proveedor-empresa" && (
          <Resource
            name="enterprise/branches"
            list={BranchList}
            edit={BranchEdit}
            create={BranchCreate}
            options={{ label: "Sucursales", parent: "settings" }}
            icon={BusinessIcon}
          />
        ),

        // permissions === "admin-sucursal" && (
        //   <Resource
        //     name="enterprise/branches"
        //     list={BranchShow}
        //     options={{ label: "Sucursal", parent: "settings" }}
        //     icon={BusinessIcon}
        //   />
        // ),
        ( permissions === "proveedor-empresa" ||
          // permissions === "proveedor-producto" ||
          permissions === "admin-sucursal"
        ) && (
          <Resource
            name="availabilityWeb"
            list={AvailabilityEdit}
            options={{ label: "Disponibilidad", parent: "settings" }}
            icon={EventAvailableIcon}
          />
        ),

        permissions !== "marketing" && (
          <Resource
            name="employee"
            list={EmployeeList}
            edit={EmployeeEdit}
            create={EmployeeCreate}
            options={{ label: "Empleados", parent: "settings" }}
            icon = {GroupIcon}
          />
        ),

        ( permissions === "proveedor-empresa" ||
          permissions === "proveedor-producto" ||
          permissions === "admin-sucursal"
        ) && (
          <Resource
            name="enterprise/customers"
            list={EnterpriseCustomerList}
            create={EnterpriseCustomerCreate}
            edit={EnterpriseCustomerEdit}
            options={{ label: "Clientes", parent: "tools" }}
            icon={GroupsIcon}
          />
        ),
        
        permissions === "proveedor-empresa" && <Resource name="profile" />,
        ( permissions === "proveedor-empresa" ||
          permissions === "admin-sucursal"
        ) && (
          <Resource
            rowClick="show"
            name="booking"
            list={BookingList}
            show={BookingShow}
            create={BookingCreate}
            options={{ label: "Reservas", parent: "tools" }}
            icon={BackupTableIcon}
          />
        ),
        (permissions === "proveedor-empresa" || permissions === "admin-sucursal") && (
          <Resource
            name="bookingBalance"
            list={(props) => (
              <FilterProvider> 
                <BalanceList {...props} /> 
              </FilterProvider>
            )}
            options={{ label: "Balance", parent: "tools" }}
            icon={MonetizationOnIcon}
          />
        ),
        permissions === "proveedor-empresa" && (
          <Resource name="updatePassword" />
        ),
        permissions === "proveedor-producto" && (
          <Resource
            name="product"
            list={ProductList}
            edit={ProductEdit}
            create={ProductCreate}
            options={{ label: "Productos" }}
          />
        ),
        (permissions === "proveedor-producto" || permissions === "admin") && (
          <Resource
            rowClick="show"
            name="sale"
            list={SaleList}
            show={SaleShow}
            create={SaleCreate}
            options={{ label: "Ventas" }}
          />
        ),
        (permissions === "proveedor-producto" || permissions === "admin") && (
          <Resource
            name="sale/balance"
            list={SaleBalanceList}
            options={{ label: "Balance de Ventas" }}
          />
        ),
        (permissions === "proveedor-empresa" || permissions === "admin-sucursal") && (
          <Resource
            name="schedule"
            list={ScheduleList}
            options={{ label: "Calendario", parent: "tools" }}
            icon={CalendarMonthIcon}
          />
        ),
        (permissions === "proveedor-empresa" || permissions === "admin-sucursal") && (
          <Resource
            name="unavailability"
            list={UnavailabilityList}
            edit={UnavailabilityEdit}
            create={UnavailabilityCreate}
            options={{ label: "Horario No Disponible", parent: "tools" }}
            icon={EventBusyIcon}
          />
        ),        
        (permissions === "proveedor-empresa" || permissions === "admin-sucursal") && (
          <Resource
            name="coupon"
            list={CouponList}
            edit={CouponEdit}
            create={CouponCreate}
            options={{ label: "Cupones", parent: "tools" }}
            icon={ConfirmationNumberIcon}
          />
        ),
        <Resource name="register" />,
        <Resource name="forgotPassword" />,
        <Resource name="resetPassword" />,
        <Resource name="availability" />,
      ]}}
      <CustomRoutes>
        <Route path="/tutorial" element={<Tutorial />} />
        <Route path="/airtable" element={<AirtablePage />} />       
        <Route
          path="/branch/:id"          
          element={<BranchShow />}
        /> 
        <Route path="/enterprise/customers/:id/services" element={<CustomerServicesPage />} />
      </CustomRoutes>
    </Admin>
  );
};
export default App;
