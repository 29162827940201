import { Box, Container, Paper, Typography } from "@mui/material";
import React from "react";

export const Tutorial = () => {
  return (
    <Box margin={12}>
      <Container maxWidth="md">
        <Typography variant="h4" mb={4}>
          Lista de Reproducción de Tutoriales:{" "}
        </Typography>
        <iframe
          width="800"
          height="500"
          src="https://www.youtube.com/embed/videoseries?list=PLx-3Bi7xrv0Lso_yUXmnfPWWi-zK1EQAr"
          title="Lista de Reproducción de Tutoriales"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Container>
    </Box>
  );
};
