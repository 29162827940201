import React from "react";
import { styled } from "@mui/material/styles";
import {
  Create as BaseCreate,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import Map from "../../components/Map";
const PREFIX = "Create";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledBaseCreate = styled(BaseCreate)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

export const Create = ({ ...props }) => (
  <StyledBaseCreate {...props}>
    <SimpleForm>
      <TextInput validate={[required()]} source="name" label="Nombre" />

      <Map source="addressInfo" />
      <TextInput
        validate={[required()]}
        source="addressInfo.street"
        label="Calle"
      />
      <TextInput source="addressInfo.phone" label="Teléfono/Celular" />

      <ReferenceArrayInput
        source="services"
        reference="service"
        filter={{
          method: "Ambos,En sucursal",
          enabled: true
        }}
      >
        <SelectArrayInput label="Servicios" />
      </ReferenceArrayInput>
    </SimpleForm>
  </StyledBaseCreate>
);
