import React from "react";
import {
  Create,
  Filter,
  required,
  ImageField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Edit,
  SimpleForm,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  ImageInput,
} from "react-admin";

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre" source="name" alwaysOn />
  </Filter>
);

function formatLogo(value) {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
}

export const CategoryList = (props) => (
  <List filters={<CategoryFilter />} {...props}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="name" label="Nombre" />
      <ImageField source="logo" title="logo" />
      <BooleanField source="state" label="Estado" />
      <ArrayField source="subCategory" label="SubCategorías">
        <SingleFieldList>
          <ChipField source="name" label="Nombre" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export const CategoryEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ImageInput
        format={formatLogo}
        validate={[required()]}
        source="logo"
        label="Logo de Categoría"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput validate={[required()]} source="name" label="Nombre" />
      <BooleanInput validate={[required()]} source="state" label="Estado" />
      <ArrayInput
        validate={[required()]}
        source="subCategory"
        label="SubCategorías"
      >
        <SimpleFormIterator>
          <ImageInput
            validate={[required()]}
            format={formatLogo}
            source="logo"
            label="Logo de SubCategoría"
            accept=".jpeg,.jpg,.png,.jfif"
            placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput validate={[required()]} source="name" label="Nombre" />
          <BooleanInput
            validate={[required()]}
            source="state"
            defaultValue={true}
            label="Estado"
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const CategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ImageInput
        validate={[required()]}
        source="logo"
        label="Logo de Categoría"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="name" label="Nombre" validate={[required()]} />
      <ArrayInput
        source="subCategory"
        label="SubCategorías"
        validate={[required()]}
      >
        <SimpleFormIterator>
          <TextInput source="name" label="Nombre" validate={[required()]} />
          <BooleanInput source="state" label="Estado" defaultValue={true} />
          <ImageInput
            validate={[required()]}
            source="logo"
            label="Logo de SubCategoría"
            accept=".jpeg,.jpg,.png,.jfif"
            placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
