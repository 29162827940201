// in src/users.js
import React from "react";
import {
  Filter,
  TextInput,
  required,
  FormDataConsumer,
  ImageField,
  Edit,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  BooleanField,
  BooleanInput,
  EmailField,
  SimpleShowLayout,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  PasswordInput,
  useChoicesContext,
  useGetList,
} from "react-admin";
import { useParams } from "react-router-dom";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre de Empresa" source="first_name" />
    <TextInput label="Razón Social" source="business_name" />
    <BooleanInput label="Usuario Habilitado" source="state"></BooleanInput>
    <BooleanInput
      label="Proveedor Verificado"
      source="provider_verified"
    ></BooleanInput>
  </Filter>
);

export const UserList = (props) => (
  <List filters={<UserFilter />} {...props}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="first_name" label="Nombre de Empresa" />
      <TextField source="business_name" label="Razón Social" />
      <ImageField
        source="picture"
        title="Imagen  no disponible"
        label="Imagen"
      />
      <EmailField source="email" />
      <TextField source="phone" label="Teléfono" />
      <TextField source="CI_NIT" label="NIT" />
      <TextField source="role" label="Rol" />
      <BooleanField source="email_verified" label="Email verificado" />
      <BooleanField source="provider_verified" label="Proveedor verificado" />
      <BooleanField source="state" label="Usuario Habilitado" />
      <BooleanField source="providerEnabledForApp" label="Habilitado para app movil" />
    </Datagrid>
  </List>
);

const WithProps = ({ children, ...props }) => children(props);

const BranchSelectInput = ({ source, providerId, ...props }) => {
  const { data, isLoading, error } = useGetList("enterprise/branches", {
    filter: { providerId },
  });

  if (isLoading) return <span>Cargando sucursales...</span>;
  if (error) return <span>Error al cargar sucursales</span>;

  return (
    <SelectInput
      source={source}
      choices={data}
      optionText="name"
      // optionValue="_id"
      // parse={(value) => {
      //   const selectedBranch = data.find((branch) => branch._id === value);
      //   return selectedBranch ? JSON.stringify(selectedBranch) : value;
      // }}
      // format={(value) => {
      //   if (typeof value === "string") {
      //     try {
      //       const parsedValue = JSON.parse(value);
      //       return parsedValue._id;
      //     } catch (error) {
      //       console.error("Error al parsear JSON:", error);
      //       return value;
      //     }
      //   }
      //   return value?._id || value;
      // }}
      optionValue="_id" 
      parse={(value) => data.find((branch) => branch._id === value)}
      format={(value) => value?._id || value}
      {...props}
    />
  );
};

export const UserEdit = (props, record) => {  
  const params = useParams()
  return (
    <Edit {...props}>
      <WithProps>
        {({ record, ...props }) => {
          return (
            <SimpleForm>
              <SimpleShowLayout>
                <TextField source="first_name" label="Nombre de Empresa" />
                <TextField source="business_name" label="Razón Social" />
                <ImageField source="pictureURL" title="Imagen no disponible" label="Imagen" />
                <EmailField source="email" />
                <TextField source="phone" label="Teléfono" />
                <TextField source="CI_NIT" label="NIT" />
                <TextField source="role" label="Rol" />
                <BooleanField source="email_verified" label="Email verificado" />
              </SimpleShowLayout>
              <ArrayInput source="branchAdmins" label="Administradores de Sucursal">
                <SimpleFormIterator>
                  <TextInput source="email" label="Correo Electrónico" validate={required()} />
                  <FormDataConsumer>
                    {({ getSource, scopedFormData }) => {
                      const passwordSource = getSource("password");
                      return scopedFormData && scopedFormData._id ? (
                        <TextField label="Contraseña (oculta)" />
                      ) : (
                        <PasswordInput source={passwordSource} label="Contraseña" validate={required()} />
                      );
                    }}
                  </FormDataConsumer>
                  <BranchSelectInput source="branch" providerId={params.id} validate={required()} />
                </SimpleFormIterator>
              </ArrayInput>
              <BooleanInput source="provider_verified" label="Proveedor verificado" />
              <BooleanInput source="state" label="Usuario Habilitado" />
              <BooleanInput source="providerEnabledForApp" label="Habilitado para app movil" />
              {/* <FormDataConsumer>
              </FormDataConsumer> */}
            </SimpleForm>
          );
        }}
      </WithProps>
    </Edit>
  );
};
