import React, { useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@mui/material";

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const ExportButton = ({ data, filename = "data.xlsx", columns, onClick, loading, clearData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleExport = () => {
    if (!data || data.length === 0) return;   
    
    const now = new Date();
    const formatter = new Intl.DateTimeFormat("es-BO", {
      timeZone: "America/La_Paz",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const datePart = formatter.format(now).replace(/\//g, "/");
    const timePart = now
      .toLocaleTimeString("es-BO", {
        timeZone: "America/La_Paz",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(/:/g, "_")
      .replace(/\s[ap]\.?\s?m\.?/gi, "");

    // Combina fecha y hora
    const formattedDateTime = `${datePart},${timePart}`;
    const fileNameWithDate = `${filename.split(".xlsx")[0]}_${formattedDateTime}.xlsx`;

    
    const formattedData = data.map((record) => {
      const formattedRecord = {};
      columns.forEach((col) => {
        const value = getNestedValue(record, col.source);

        formattedRecord[col.label] = col.transform ? col.transform(value) : value;
      });

      return formattedRecord;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");
  
    worksheet["!cols"] = columns.map((col) => ({ wch: col.width || 15 }));
    
    XLSX.writeFile(workbook, fileNameWithDate, { bookType: "xlsx", type: "binary", cellDates: true, encoding: "UTF-8" });

    clearData();
  };


  useEffect(() => {    
    if (!loading && data.length > 0) {      
      handleExport();
    }
  }, [loading, data]);

  const handleClick = async () => {    
    if (data.length > 0) {
      handleExport();
    } else {      
      await onClick();
    }
  };

  return (
    <Button onClick={handleClick} startIcon={<DownloadIcon />} disabled={loading} style={{ fontSize: 12 }}>
      {loading ? <CircularProgress size={24} /> : isSmallScreen ? "" : "Exportar"}
    </Button>
  );
};

ExportButton.propTypes = {
  data: PropTypes.array.isRequired,
  filename: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      transform: PropTypes.func,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  clearData: PropTypes.func.isRequired,
};

export default ExportButton;
