import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Create as BaseCreate,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  ArrayInput,
  NumberInput,
  required,
  email,
  DateInput,
  SelectInput,
} from "react-admin";
import Map from "../../components/Map";
import countryCodes from "./phone_code.json"

const PREFIX = 'Create';

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`
};

const StyledBaseCreate = styled(BaseCreate)({
  [`& .${classes.inlineBlock}`]: { display: "inline-flex", marginRight: "1rem" },
});


export const Create = (({  ...props }) => {

  const [selectedCountryCode, setSelectedCountryCode] = useState("591"); 
  
  const handleCountryChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  return (
  <StyledBaseCreate {...props}>
    <SimpleForm>
      <TextInput
        validate={[required()]}
        source="fullName"
        label="Nombre Completo"
      />
      <SelectInput
        label="Código de País"
        source="countryCode"
        choices={countryCodes.paises}
        optionText={(value) => `${value.name} ( +${value.dial_code} )`}
        optionValue="dial_code"
        onChange={handleCountryChange}
        validate={[required()]}
        defaultValue={countryCodes.paises.find((e) => e.code === "BO")?.dial_code}
      />
      <NumberInput source="phone" label="Teléfono/Celular" validate={[required()]} />
      <TextInput validate={[email()]} source="email" label="Email" />
      <DateInput label="Fecha de Nacimiento" source="birthdate" />
      <SelectInput
        label="Género"
        source="gender"
        choices={[
          { id: "hombre", name: "Hombre" },
          { id: "mujer", name: "Mujer" },
        ]}
      />
      <ArrayInput
        source="addresses"
        label="Direcciones"
      >
        <SimpleFormIterator>
          <Map/>
          {/* <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}> */}
          <TextInput validate={[required()]} source="name" label="Nombre" />
          <TextInput validate={[required()]} source="street" label="Calle" />
          <TextInput source="house" label="Número de casa" />
          <TextInput source="reference" label="Referencia" />
          {/* </div> */}
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </StyledBaseCreate>
)});
