import React, { useState, useEffect } from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  DateInput,
  TextInput,
  useRefresh,
  useListContext,
} from "react-admin";
import Paper from "@mui/material/Paper";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

const MyDatagrid = (props) => {
  let sum = 0;
  let com = 0;
  let totCom = 0;
  const { data } = useListContext();
  data?.map((e) => {
    if (e?.state == "Concluido") {
      sum += e.price;
      totCom += e.commission;
      if (e.commissionPaid) {
        com += e.commission;
      }
    }
  });
  props.totalIn(sum, totCom);
  props.commissionPaid(com);
  return (
    <Datagrid rowStyle={postRowStyle} bulkActionButtons={false} {...props} />
  );
};

const AppointmentFilter = (props) => (
  <Filter {...props}>
    <DateInput label="Fecha de Inicio" source="date1" />
    <DateInput label="Fecha de Final" source="date2" />
    <TextInput label="Empleado" source="employeeName" />
  </Filter>
);

const postRowStyle = (record, index) => ({
  backgroundColor: !record?.commissionPaid ? "#ffdfd4" : "",
});

export const BalanceDeliveryList = (props) => {
  const refresh = useRefresh();
  useEffect(() => {
    const interval = setInterval(() => refresh(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  let [totalIn, setTotalIn] = useState(0);
  let [totalCom, setTotalCom] = useState(0);
  let [commissionPaid, setCommissionPaid] = useState(0);
  let [refreshDate, setRefreshDate] = useState(new Date());
  const callbackFunction = (childData, totCom) => {
    setTotalIn(childData);
    setTotalCom((Math.round(totCom * 100) / 100).toFixed(2));
  };

  const callbackCommission = (childData) => {
    setCommissionPaid(childData);
  };

  return (
    <div>
      <List
        sort={{ field: "startDate", order: "DESC" }}
        rowClick="edit"
        bulkActionButtons={false}
        filters={<AppointmentFilter />}
        {...props}
      >
        <MyDatagrid
          totalIn={callbackFunction}
          commissionPaid={callbackCommission}
        >
          <IconField refreshDate={refreshDate} />
          <TextField source="employee.fullName" label="Empleado" />
          <DateField
            source="startDate"
            label="Fecha de Inicio"
            showTime
            locales="es-ES"
          />
          <TextField source="price" label="Precio" />
          <TextField source="paymentMethod" label="Método de Pago" />
          <TextField source="settings.commissionRate" label="% de Comisión" />
          <TextField source="commission" label="Comisión (Bs.)" />
          <BooleanField source="commissionPaid" label="Comisión pagada" />
        </MyDatagrid>
      </List>
      <div>
        <Paper>
          <h3>Ingresos Totales de Proveedores: Bs.{totalIn}</h3>
          <h3>Comisiones Totales: Bs.{totalCom}</h3>
        </Paper>
        <Paper>
          <h3>Comisiones Totales: Bs.{totalCom}</h3>
          <h3>Comisiones Pagadas: Bs.{commissionPaid.toFixed(2)}</h3>
          <h3>
            Comisiones en deuda: Bs.{(totalCom - commissionPaid).toFixed(2)}
          </h3>
        </Paper>
      </div>
    </div>
  );
};

const IconField = ({ source, record = {}, refreshDate }) => {
  var d1 = new Date(refreshDate);
  var d2 = new Date(record.appointmentDate);
  if (d2 > d1)
    return <NewReleasesIcon color="primary" fontSize="medium" label="Nuevo" />;
  else {
    return null;
  }
};
